import * as Yup from 'yup'
// import { I18n } from 'aws-amplify'
import { useTranslation } from 'react-i18next'
import { isCPF } from '../yup-methods-validations/identity'
import { isValidCardNumber } from '../yup-methods-validations/credit-card'
import { isValidDateExp } from '../yup-methods-validations/isValidDateExp'

Yup.addMethod(Yup.string, 'cpf', function (message) {
  return Yup.mixed().test('cpf', message, (value) => isCPF(value))
})

Yup.addMethod(Yup.string, 'isValidCardNumber', function (message) {
  return Yup.mixed().test('isValidCardNumber', message, (value) => isValidCardNumber(value))
})

Yup.addMethod(Yup.string, 'inputDateExp', function (message) {
  return Yup.mixed().test('isValidaDateExp', message, (value) => isValidDateExp(value))
})

export const getValidationSchemaByName = (name) => {
  const { t } = useTranslation()

  if (!Array.isArray(name)) {
    switch (name) {
      case 'inputRequired':
        return Yup.string()
          .required(t('formValidations.ValidateRequiredField'))
      case 'emailRequired':
        return Yup.string()
          .email(t('formValidations.ValidateInvalidEmail'))
          .required(t('formValidations.ValidateRequiredField'))
      case 'passwordMinRequired':
        return Yup.string()
          .min(8, t('formValidations.ValidateMinPassword'))
          .required(t('formValidations.ValidateRequiredField'))
      case 'passwordWithMatches':
        return Yup.string()
          .required(t('formValidations.ValidateRequiredField'))
          .min(6, t('formValidations.ValidateMinPassword'))
          .max(24, t('formValidations.ValidateMaxPassword'))
          // .matches(/[0-9]/, t('ValidateOneNumber'))
          // .matches(/[a-zA-Z]+/, t('ValidateOneLetter'))
          // .matches(/[a-z]/, t('ValidateOneLowerChar'))
          // .matches(/[A-Z]/, t('ValidateOneUpperChar'))
          // .matches(/[!@#$%*()_/\\\-+^&{}:;?.]/, t('ValidateOneSpecialChar'))
      case 'confirmPassword':
        return Yup.string().when('password', {
          is: (val) => val && val.length >= 6,
          then: Yup.string()
            .oneOf([Yup.ref('password')], t('formValidations.ValidatePasswordNotEquals'))
            .required(t('formValidations.ValidateRequiredField'))
        })
      case 'inputIdentity':
        return Yup.string()
          .required(t('formValidations.ValidateRequiredField'))
          .cpf(t('formValidations.ValidateInvalidCPF'))
      case 'inputCreditCard':
        return Yup.string()
          .required(t('formValidations.ValidateRequiredField'))
          .isValidCardNumber(t('formValidations.ValidateInvalidCardNumber'))
      case 'inputDateExp':
        return Yup.string()
          .required(t('formValidations.ValidateRequiredField'))
          .inputDateExp(t('formValidations.ValidateInvalidDateExp'))
      case 'inputRequiredCode':
        return Yup.string()
          .required(t('formValidations.ValidateRequiredField'))
          .matches(/[0-9]/, t('formValidations.ValidateOnlyNumbers'))
      case 'isValidName':
        return Yup.string()
          .required(t('formValidations.ValidateRequiredField'))
          .min(2, t('formValidations.ValidateMinName'))
      case 'inputToken':
        return Yup.string()
          .required(t('formValidations.ValidateRequiredField'))
          .max(5, t('formValidations.ValidateMaxToken'))
      default:
        throw new Error(t('formValidations.ValidationNotFound', { name }))
    }
  }
}
