/* eslint-disable no-console */
/* eslint-disable space-before-function-paren */
import axios from 'axios'

const basicAuth = 'Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuVDJ4cFgydGZVMlZ5ZG1salpWOUZZMjl0YldWeVkyVTZaWGxLYUdKSFkybFBhVXBKVlhwSk1VNXBTamt1VkRCNFNpNW5SV2xmUkU5S2RteFBZV2hmVW5FeGEwRklXbVpsTVU5TGJYSlRlRVJTWlRkSmEwbHZOVzFSUjNWci5XaUI1VXhpel9sSWVjdS1lRmFUTW9uM3BuUWJFcjNmQVJkLWZwRzg5SW5FOmV5SmhiR2NpT2lKSVV6STFOaUo5LlQyeHBYM05mVTJWeWRtbGpaVjlGWTI5dGJXVnlZMlU2WlhsS2FHSkhZMmxQYVVwSlZYcEpNVTVwU2prdVZEQjRTaTVuUldsZlJFOUtkbXhQWVdoZlVuRXhhMEZJV21abE1VOUxiWEpUZUVSU1pUZEphMGx2TlcxUlIzVnIuXzJpNUhBY2tGaDVsc2Z1d0tnRFdCb1V2dEx5clFjOUJQazZrUnVhUlZROA=='

export async function GetLiveZoom() {
  const apiUrl = 'https://api-v2-production-node.todasgroup.com.br/api/v1/generate-token'
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: basicAuth }
    })
    return JSON.stringify(response.data)
  } catch (error) {
    console.error('Erro ao validar email:', error)
    return false
  }
}

export async function userAcessLive(userId) {
  const apiUrl = `https://api-v2-production-node.todasgroup.com.br/api/v1/permission-live-zoom/${userId}`
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: basicAuth }
    })
    return response.data
  } catch (error) {
    console.error('Erro ao verificar usuario', error)
    return false
  }
}

export async function PostWatchedLive(data) {
  const apiUrl = 'https://api-v2-production-node.todasgroup.com.br/api/v1/create-user-live-zoom'
  try {
    const response = await axios.post(apiUrl, data, {
      headers: { Authorization: basicAuth }
    })
    return JSON.stringify('resposta', response.data)
  } catch (error) {
    console.error('Erro ao salvar dados do usuario:', error)
    return false
  }
}

export async function GetDetailsZoom(dateLive) {
  const apiUrl = `https://api-v2-production-node.todasgroup.com.br/api/v1/zoom-live-by-date?date=${dateLive}`
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: basicAuth }
    })
    return response.data
  } catch (error) {
    console.error('Erro ao verificar usuario', error)
    return false
  }
}

export const GetDateHours = () => {
  const now = new Date()

  // Formatação para 'YYYY-MM-DD'
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`

  // Formatação para 'HH:mm'
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const formattedHour = `${hours}:${minutes}`

  return { formattedDate, formattedHour }
}
