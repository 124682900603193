import { useState, useEffect } from 'react'
import { getUser } from './storage/user'
import { handleGetUserAssessment } from '../pages/assessment/controller/opt-in'

const useUserB2C = () => {
  const [userB2c, setUserB2c] = useState(null)
  const user = getUser()

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.user?.id) return

      try {
        const parentId = await handleGetUserAssessment(user.user.id)
        setUserB2c(parentId?.data?.customers?.parent_id === 32692)
      } catch (error) {
        console.error('Erro ao buscar parentId:', error)
      }
    }

    fetchData()
  }, [user?.user?.id])

  return userB2c
}

export default useUserB2C
