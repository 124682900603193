import { makeAuth } from '../../../factories'
import { getUser } from '../../../utils/storage/user'
import { settings } from '../../../../main/configs/settings'
import { MobileHelper } from '../../../helpers/mobile-helper/mobile-helper'

export const sendMeTo = (userInfo, meta) => {
  const { hasActiveSubscription, hasCustomerFolders, hasTrialSubscription, accessFreeContent } = userInfo

  if (meta.subscriptionIsNotRequired && hasCustomerFolders) return settings.dashboardRoute
  if ((hasActiveSubscription || hasTrialSubscription) && !hasCustomerFolders && meta.verifyActiveSubscritionAndNotHasAccess) return '/atualizar-cartao'
  if (meta.subscriptionIsNotRequired || hasCustomerFolders || accessFreeContent) return 'next'
  if (hasActiveSubscription || hasTrialSubscription) return '/atualizar-cartao'
  // caso de algum problema nas rotas, retirar o ultimo if:
  if (!accessFreeContent && !hasCustomerFolders) return 'next'
  return '/planos'
}

export const guardsRoutes = async (to, from, next) => {
  await MobileHelper.setMobile(to.location.search || from.location.search)

  if (to.meta.auth) {
    try {
      await makeAuth()
      const { info, user } = getUser()
      const redirect = sendMeTo({
        ...info,
        accessFreeContent: user.accessFreeContent
      }, to.meta)
      if (redirect !== 'next') {
        next.redirect(redirect)
      }
      next()
    } catch (e) {
      // next.redirect(`/login?redirect=${btoa(window.location.href)}`)
      next.redirect('/login')
      return
    }
  }
  next()
}
