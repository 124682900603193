/* eslint-disable no-console */
import apiJwt from '../http/axios-client/axios'
import nookies from 'nookies'

export const Login = async (email, password, isAdmin) => {
  try {
    const response = await apiJwt.post('/academy/service-login', { email, password })
    if (response.data.data.message === 'SUCCESS') {
      const token = response.data.data.token
      nookies.set(undefined, 'jwtToken', token, {
        maxAge: 24 * 60 * 60,
        path: '/'
      })
      const refresh = response.data.data.refreshToken
      nookies.set(undefined, 'refreshToken', refresh, {
        maxAge: 5 * 30 * 24 * 60 * 60, // 5 meses
        path: '/'
      })
    }
    if (isAdmin) {
      const token = response.data.data.token
      nookies.set(undefined, 'todasgroup-token', token, {
        maxAge: 24 * 60 * 60,
        path: '/',
        domain: '.todasgroup.com.br'
      })
    }
    return response.data
  } catch (error) {
    console.error('Erro durante o login:', error)
    return { success: false, message: 'Erro de conexão. Tente novamente mais tarde.' }
  }
}

export const NewpasswordAcademy = async (data) => {
  try {
    const response = await apiJwt.post('/academy/service-verify-token', data)

    if (response.data.data && response.data.data.status !== 400) {
      return response.data
    }

    return response.data
  } catch (error) {
    return { success: false, message: 'Unexpected error', error }
  }
}

export const ServiceForgotPassword = async (data) => {
  try {
    await apiJwt.post('/academy/service-forgot', { email: data })
    return { success: true }
  } catch (error) {
    return { success: false, error }
  }
}

export const CreateUser = async (data) => {
  try {
    const response = await apiJwt.post('/academy/service/create-user/by-register', data)

    const { status, message } = response.data.data

    if (status === 400) {
      if (message === 'EMAIL_ALREADY_REGISTERED') {
        return { success: false, error: 'O e-mail já está registrado.' }
      }

      if (message === 'DOCUMENT_ALREADY_REGISTERED') {
        return { success: false, error: 'O documento já está registrado.' }
      }
    }

    if (message === 'SUCCESS' && status === 200) {
      return { success: true }
    }
  } catch (error) {
    console.log(error)
    return { success: false, error: 'Erro ao processar a solicitação.' }
  }
}

export const UpdatePassword = async (data) => {
  try {
    const response = await apiJwt.post('/academy/service-update-password', data)
    if (response.data.data.message === 'SUCCESS') {
      return response.data
    }
  } catch (error) {
    return { success: false, error }
  }
}

export const refreshToken = async () => {
  try {
    const cookies = nookies.get()
    const refresh = cookies.refreshToken

    if (!refresh) {
      console.log('Nenhum refresh token encontrado.')
      return false
    }

    const response = await apiJwt.post('/academy/refresh-token',
      { refreshToken: refresh }
    )

    const newAccessToken = response.data.data.token
    const newRefreshToken = response.data.data.refreshToken

    nookies.set(undefined, 'jwtToken', newAccessToken, {
      maxAge: 24 * 60 * 60,
      path: '/'
    })

    nookies.set(undefined, 'refreshToken', newRefreshToken, {
      maxAge: 5 * 30 * 24 * 60 * 60, // 5 meses
      path: '/'
    })

    // Retorna a resposta completa para o interceptor
    return response
  } catch (error) {
    console.error(
      'Erro ao renovar token:',
      error?.response?.status,
      error?.message
    )

    if (error?.response?.status === 403) {
      console.warn('Refresh token inválido. Redirecionando para login...')
    }

    return false
  }
}

export const Verifyb2cemail = async (email) => {
  try {
    const response = await apiJwt.get(`/b2c/getEmailExists/${email}`)
    console.log(response.data)
    return response.data
  } catch (error) {
    console.log('erro ao verificar email b2c', error)
  }
}
