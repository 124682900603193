/* eslint-disable space-before-function-paren */
import axios from 'axios'
import apiJwt from '../../../../infra/http/axios-client/axios'
// import { Auth } from 'aws-amplify'

// export async function handleValidarCodigo(key) {
//   const apiUrl = 'https://api-assessment-laravel.todasgroup.com.br/api/verifykey'
//   try {
//     const response = await axios.post(apiUrl, { key: key })
//     return response.data
//   } catch (error) {
//     console.error('Erro ao validar codigo:', error)
//     return false
//   }
// }

export async function handleGetTokenCompany() {
  try {
    const response = await axios.get(
      'https://api-v2-production-node.todasgroup.com.br/api/v1/all-enterprises',
      {
        headers: {
          Authorization:
            'Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuVDJ4cFgydGZVMlZ5ZG1salpWOUZZMjl0YldWeVkyVTZaWGxLYUdKSFkybFBhVXBKVlhwSk1VNXBTamt1VkRCNFNpNW5SV2xmUkU5S2RteFBZV2hmVW5FeGEwRklXbVpsTVU5TGJYSlRlRVJTWlRkSmEwbHZOVzFSUjNWci5XaUI1VXhpel9sSWVjdS1lRmFUTW9uM3BuUWJFcjNmQVJkLWZwRzg5SW5FOmV5SmhiR2NpT2lKSVV6STFOaUo5LlQyeHBYM05mVTJWeWRtbGpaVjlGWTI5dGJXVnlZMlU2WlhsS2FHSkhZMmxQYVVwSlZYcEpNVTVwU2prdVZEQjRTaTVuUldsZlJFOUtkbXhQWVdoZlVuRXhhMEZJV21abE1VOUxiWEpUZUVSU1pUZEphMGx2TlcxUlIzVnIuXzJpNUhBY2tGaDVsc2Z1d0tnRFdCb1V2dEx5clFjOUJQazZrUnVhUlZROA=='
        }
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
  }
}

export async function handleBuscarEstados() {
  try {
    const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
    if (!response.ok) {
      throw new Error('Erro ao buscar estados')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Erro ao buscar estados:', error)
    throw error
  }
}

export async function handleBuscarCidade(UF) {
  try {
    const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${UF}/municipios`)
    const data = response.json()
    return data
  } catch (error) {
    console.error('Erro ao buscar cidades:', error)
  }
}

// export async function handleBuscarEmpresas() {
//   try {
//     const response = await axios.get('https://api-assessment-laravel.todasgroup.com.br/api/companys/list')
//     const respondeOrdenado = response.data.sort((a, b) => {
//       const nameA = a.name.toUpperCase()
//       const nameB = b.name.toUpperCase()
//       if (nameA < nameB) {
//         return -1
//       }
//       if (nameA > nameB) {
//         return 1
//       }
//       return 0
//     })
//     return respondeOrdenado
//   } catch (error) {
//     console.error('Erro ao buscar empresas', error)
//   }
// }

// export async function handleSubmitUser(data) {
//   try {
//     const response = await axios.post(
//       'https://api-assessment-laravel.todasgroup.com.br/api/register/user',
//       data
//     )
//     /* eslint-disable-next-line no-console */
//     console.log('Resposta da API:', response.data)
//     return true
//   } catch (error) {
//     console.error('Ocorreu um erro:', error)
//   }
// }

export async function handleGetUserAssessment(idUser) {
  try {
    const response = await axios.get(
      `https://api-v2-production-node.todasgroup.com.br/api/v1/users/${idUser}`,
      {
        headers: { Authorization: `Basic ${process.env.REACT_APP_TOKEN_ASSESSMENT}` }
      }

    )
    /* eslint-disable-next-line no-console */
    // console.log('Resposta da API:', response.data)
    return response?.data
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.error('Ocorreu um erro:', error)
  }
}

export async function handleUpdateUser(data) {
  try {
    const response = await axios.post(
      'https://api-v2-production-node.todasgroup.com.br/api/v1/user',
      // 'https://api-v2-homolog-node.todasgroup.com.br/api/v1/user',
      { data },
      {
        headers: { Authorization: `Basic ${process.env.REACT_APP_TOKEN_ASSESSMENT}` }
      }
    )
    /* eslint-disable-next-line no-console */
    console.log('Resposta da API UPDATE:', response?.data)
    return response?.data
  } catch (error) {
    console.error('Ocorreu um erro:', error)
  }
}

// GET para trazer as perguntas de impacto
export async function handleGetQuestionImpact() {
  try {
    const response = await axios.get('https://api-v2-production-assessment.todasgroup.com.br/api/question/impacto')
    return response.data
  } catch (e) {
    console.error(e)
  }
}

// POST para submeter as perguntas de impacto
export async function handleSubmitQuestionImpact(data) {
  try {
    // const session = await Auth.currentSession()
    const response = await axios.post(
      'https://api-v2-production-assessment.todasgroup.com.br/api/question/impacto/answers',
      data
      // {
      //   headers: { Authorization: session.getIdToken().getJwtToken() }
      // }
    )
    /* eslint-disable-next-line no-console */
    console.log('Resposta da API:', response.data)
    return true
  } catch (error) {
    console.error('Ocorreu um erro:', error)
  }
}

// GET para trazer as perguntas de Potencia
export async function handleGetQuestionPower() {
  try {
    const response = await axios.get('https://api-v2-production-assessment.todasgroup.com.br/api/question/potencial')
    return response.data
  } catch (e) {
    console.error(e)
  }
}

// POST para submeter as perguntas de potencia
export async function handleSubmitQuestionPower(data) {
  try {
    // const session = await Auth.currentSession()
    const response = await axios.post(
      'https://api-v2-production-assessment.todasgroup.com.br/api/question/potencial/answers',
      data)
    /* eslint-disable-next-line no-console */
    console.log('Resposta da API:', response.data)
    return true
  } catch (error) {
    console.error('Ocorreu um erro:', error)
  }
}

export async function handleGetTokenProject(token) {
  try {
    const response = await axios.get(`https://api-v2-production-node.todasgroup.com.br/api/v1/findProject/${token}`,
      {
        headers: {
          Authorization:
            'Basic ZXlKaGJHY2lPaUpJVXpJMU5pSjkuVDJ4cFgydGZVMlZ5ZG1salpWOUZZMjl0YldWeVkyVTZaWGxLYUdKSFkybFBhVXBKVlhwSk1VNXBTamt1VkRCNFNpNW5SV2xmUkU5S2RteFBZV2hmVW5FeGEwRklXbVpsTVU5TGJYSlRlRVJTWlRkSmEwbHZOVzFSUjNWci5XaUI1VXhpel9sSWVjdS1lRmFUTW9uM3BuUWJFcjNmQVJkLWZwRzg5SW5FOmV5SmhiR2NpT2lKSVV6STFOaUo5LlQyeHBYM05mVTJWeWRtbGpaVjlGWTI5dGJXVnlZMlU2WlhsS2FHSkhZMmxQYVVwSlZYcEpNVTVwU2prdVZEQjRTaTVuUldsZlJFOUtkbXhQWVdoZlVuRXhhMEZJV21abE1VOUxiWEpUZUVSU1pUZEphMGx2TlcxUlIzVnIuXzJpNUhBY2tGaDVsc2Z1d0tnRFdCb1V2dEx5clFjOUJQazZrUnVhUlZROA=='
        }
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
  }
}

// GET trazendo os resultados da pesquisa de potencia
export async function handleGetResultPerfil(userId, pesquisaType) {
  try {
    const response = await axios.post(
      'https://api-v2-production-assessment.todasgroup.com.br/api/question/potencial/resultado',
      { user_id: userId, pesquisaType: pesquisaType }
    )
    return response.data
  } catch (error) {
    console.error('Erro ao buscar dados do usuário:', error)
  }
}

// GET trazendo informaçoes sobre o usuario e a empresa que ele faz parte
export async function handleGetUserCompany(userId) {
  try {
    const response = await axios.post(
      'https://api-v2-production-assessment.todasgroup.com.br/api/user/verify',
      { user: userId }
    )
    return response.data
  } catch (error) {
    console.error('Erro ao buscar dados do usuário:', error)
  }
}

// // Criação de uma chave na empresa identificando quais pesquisas ela comprou
// export async function handleGetIdentifySearch (companyId) {
//   try {
//     const response = await axios.get(
//       'https://api-assessment-laravel.todasgroup.com.br/api/COLOCARURL',
//       { user: companyId }
//     )
//     return response.data
//   } catch (error) {
//     console.error('Erro ao buscar dados:', error)
//   }
// }

// // verifica se o usuário já fez a pesquisa de impacto ou potência
// export async function handleGetIdentifyUserShearch (userId) {
//   try {
//     const response = await axios.get(
//       'https://api-assessment-laravel.todasgroup.com.br/api/COLOCARURL',
//       { user: userId }
//     )
//     return response.data
//   } catch (error) {
//     console.error('Erro ao buscar dados:', error)
//   }
// }

export const VerifyEmailRegistered = async (email) => {
  try {
    const response = await apiJwt.post('/academy/service/config/verify-by-email', email)

    if (response.data.data) {
      return response.data.data.message
    }
  } catch (error) {
    return { success: false, message: 'Unexpected error', error }
  }
}

export const UpdateProfile = async (data) => {
  try {
    const response = await apiJwt.post('/academy/service/resign', data)

    if (response.data.data.status === 200) {
      return response.data.data.message
    }
    if (response.data.data.messagem && response.data.data.status === 400) {
      return response.data.message
    }
  } catch (error) {
    return { success: false, message: 'Unexpected error', error }
  }
}
