// import jwt from 'jwt-simple'
// import nookies from 'nookies'
// import { api } from '../../infra/http/remote'
// import { getUser, setUser } from '../utils/storage/user'
// import { getSettings, setSettings } from '../utils/storage/settings'
// import { outdatedCache } from '../utils/storage/date'
// import { UsersHelper } from '../helpers/users-helper/users-helper'

// export const makeAuth = async () => {
//   try {
//     const cookies = nookies.get()
//     const token = cookies.jwtToken

//     if (!token) {
//       throw new Error('Token não encontrado nos cookies')
//     }

//     const decodedToken = jwt.decode(token, process.env.REACT_APP_SECRET_KEY_API_V2)
//     const email = decodedToken.email

//     const lastUpdated = await outdatedCache()
//     const user = getUser()

//     if (
//       lastUpdated ||
//       !user ||
//       ('user' in user && user.user.email !== email) ||
//       ('info' in user && !user.info.hasCustomerFolders)
//     ) {
//       const { data: me } = await api.get('/me', {
//         headers: {
//           Accept: 'application/json'
//         }
//       })
//       const userMe = me?.[0]
//       const user = UsersHelper.run(userMe).build()
//       await setUser(user)
//     }

//     if (lastUpdated || !getSettings()) {
//       const settingsResponse = await api.get('/settings')
//       const settings = settingsResponse.data['hydra:member']
//       await setSettings(settings)
//     }
//   } catch (e) {
//     throw new Error(e.message)
//   }
// }

import jwt from 'jwt-simple'
import nookies from 'nookies'
import { api } from '../../infra/http/remote'
import { getUser, setUser } from '../utils/storage/user'
import { getSettings, setSettings } from '../utils/storage/settings'
import { outdatedCache } from '../utils/storage/date'
import { UsersHelper } from '../helpers/users-helper/users-helper'
import { refreshToken } from '../../infra/auth-jwt/auth'

export const makeAuth = async () => {
  try {
    let cookies = nookies.get()
    let token = cookies.jwtToken

    // Se o token não for encontrado, tenta renovar o token
    if (!token) {
      // eslint-disable-next-line no-console
      console.log('Token não encontrado. Tentando renovar...')
      const refreshResponse = await refreshToken()

      if (refreshResponse) {
        // Atualiza o token nos cookies
        token = refreshResponse.data.data.token
        nookies.set(undefined, 'jwtToken', token, {
          maxAge: 24 * 60 * 60,
          path: '/'
        })

        // Atualiza os cookies após a renovação
        cookies = nookies.get()
      } else {
        throw new Error('Falha ao renovar o token')
      }
    }

    // Decodifica o token para verificar a expiração
    const decodedToken = jwt.decode(token, process.env.REACT_APP_SECRET_KEY_API_V2)
    const expirationTime = decodedToken.exp * 1000 // Converte para milissegundos
    const currentTime = Date.now()

    // Verifica se o token expirou
    if (expirationTime < currentTime) {
      // eslint-disable-next-line no-console
      console.log('Token expirado. Tentando renovar...')
      const refreshResponse = await refreshToken()

      if (refreshResponse) {
        // Atualiza o token nos cookies
        token = refreshResponse.data.data.token
        nookies.set(undefined, 'jwtToken', token, {
          maxAge: 24 * 60 * 60,
          path: '/'
        })

        // Decodifica o novo token
        const newDecodedToken = jwt.decode(token, process.env.REACT_APP_SECRET_KEY_API_V2)
        const email = newDecodedToken.email

        // Continua o processo de autenticação com o novo token
        const lastUpdated = await outdatedCache()
        const user = getUser()

        if (
          lastUpdated ||
          !user ||
          ('user' in user && user.user.email !== email) ||
          ('info' in user && !user.info.hasCustomerFolders)
        ) {
          const { data: me } = await api.get('/me', {
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token}` // Usa o novo token
            }
          })
          const userMe = me?.[0]
          const user = UsersHelper.run(userMe).build()
          await setUser(user)
        }

        if (lastUpdated || !getSettings()) {
          const settingsResponse = await api.get('/settings', {
            headers: {
              Authorization: `Bearer ${token}` // Usa o novo token
            }
          })
          const settings = settingsResponse.data['hydra:member']
          await setSettings(settings)
        }

        return // Sai da função após renovar o token com sucesso
      } else {
        throw new Error('Falha ao renovar o token')
      }
    }

    // Se o token não expirou, continua o processo de autenticação
    const email = decodedToken.email
    const lastUpdated = await outdatedCache()
    const user = getUser()

    if (
      lastUpdated ||
      !user ||
      ('user' in user && user.user.email !== email) ||
      ('info' in user && !user.info.hasCustomerFolders)
    ) {
      const { data: me } = await api.get('/me', {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      const userMe = me?.[0]
      const user = UsersHelper.run(userMe).build()
      await setUser(user)
    }

    if (lastUpdated || !getSettings()) {
      const settingsResponse = await api.get('/settings', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      const settings = settingsResponse.data['hydra:member']
      await setSettings(settings)
    }
  } catch (e) {
    console.error('Erro no makeAuth:', e.message)
    throw new Error(e.message)
  }
}
