import React, { useEffect, useRef, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '../../assets/icons'
import { Box, Flex } from '../ui/components'
import Avatar from '../ui/layout/avatar/avatar'
// import LeftBar from '../left-bar/left-bar'
import { customTheme } from '../../styles/theme'
import { SobreMim } from '../../assets/icons/menu-1'
import { NotasIcon } from '../../assets/icons/menu-2'
import { ContentIcon } from '../../assets/icons/menu-3'
import { GrayVirgula } from '../../assets/icons/menu-4'
import { useTranslation } from 'react-i18next'
import usersPermissions from '../../../utils/mentor-users/user-permission'

const UserWidget = ({ user }) => {
  const match = useRouteMatch()
  const node = useRef(null)
  const [dropdown, setDropdown] = useState(false)

  const { t } = useTranslation()

  const findPermission = usersPermissions.find((item) => {
    return item.email === user.email
  })

  const toggleDropDown = () => {
    setDropdown(prev => !prev)
  }

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) return
    setDropdown(false)
  }

  useEffect(() => {
    if (dropdown) document.addEventListener('mousedown', handleClickOutside)
    else document.removeEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [dropdown])

  useEffect(() => {
    setDropdown(false)
  }, [match])

  return (
    <Box ml={20} pos="relative" zIndex={9}>
      <ProfileHandle alignItems="center" onClick={toggleDropDown} style={{ cursor: 'pointer' }}>
        {user.avatar.indexOf('/static/media/') !== -1
          ? <Icon name="user" />
          : <Avatar src={user.avatar} alt={user.name} />}
        <h3>{t('profile.perfil')}</h3>
        <ArrowDown />
      </ProfileHandle>
      {dropdown && (
        <Dropdown ref={node}>
          <BoxDrop>
            <BoxDropItem to="/editar-perfil">
              <SobreMim />
              <span>{t('profile.me')}</span>
            </BoxDropItem>
            <BoxDropItem to="/notas">
              <NotasIcon />
              <span>{t('profile.notes')}</span>
            </BoxDropItem>
            <BoxDropItem to="/conteudos">
              <ContentIcon />
              <span>{t('profile.watch')}</span>
            </BoxDropItem>

            {findPermission && (
              <BoxDropItem to="/mentoria">
                <GrayVirgula />
                <span>Mentorada</span>
              </BoxDropItem>
            )}

            <BoxDropItem to="/progresso-habilidade">
              <GrayVirgula />
              <span>{t('Progresso e Certificados')}</span>
            </BoxDropItem>

            <BoxDropItem to="/faq">
              <GrayVirgula />
              <span>{t('profile.todas')}</span>
            </BoxDropItem>
            <BoxDropItem to="/sair">
              <Icon name="logout" color="#9d9d9d" h />
              <span>{t('profile.out')}</span>
            </BoxDropItem>
          </BoxDrop>
        </Dropdown>
      )}
    </Box>
  )
}

UserWidget.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserWidget

const Dropdown = styled.div`
  position: absolute;
  right: 0;
  width: 200px;
  padding-top: 10px;
  top: auto;
  > div {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgb(189 189 189);
  }
`

const ProfileHandle = styled(Flex)`
  h3 {
    color: ${customTheme.colors.purple};
    font-size: 16px;
    margin: 0 5px 0 15px;
  }
`

const ArrowDown = styled.div`
  border-top: ${customTheme.colors.purple} 9px solid;
  border-bottom: transparent 0 solid;
  border-right: transparent 8px solid;
  border-left: transparent 8px solid;
  border-radius: 5px;
`

const BoxDrop = styled.div`
  background: #F6F4F3;
  border-radius: 6px;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
`

const BoxDropItem = styled(Link)`
  text-decoration: none;
  margin-bottom: 8px;
  color: ${customTheme.colors.purple};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 14px;
    font-weight: bold;
    padding: 10px 5px;
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    width: 100%;
  }

  i {
    width: 30px;
    margin-right: 7px;
  }

  > svg {
    width: 22px;
    margin-right: 13px;
    margin-top: 10px;
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;

    span {
      border: none;
      margin-bottom: -12px;
    }
  }
`
