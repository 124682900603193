import axios from 'axios'
const API_TOKEN = 'ZXlKaGJHY2lPaUpJVXpJMU5pSjkuVDJ4cFgydGZVMlZ5ZG1salpWOUZZMjl0YldWeVkyVTZaWGxLYUdKSFkybFBhVXBKVlhwSk1VNXBTamt1VkRCNFNpNW5SV2xmUkU5S2RteFBZV2hmVW5FeGEwRklXbVpsTVU5TGJYSlRlRVJTWlRkSmEwbHZOVzFSUjNWci5XaUI1VXhpel9sSWVjdS1lRmFUTW9uM3BuUWJFcjNmQVJkLWZwRzg5SW5FOmV5SmhiR2NpT2lKSVV6STFOaUo5LlQyeHBYM05mVTJWeWRtbGpaVjlGWTI5dGJXVnlZMlU2WlhsS2FHSkhZMmxQYVVwSlZYcEpNVTVwU2prdVZEQjRTaTVuUldsZlJFOUtkbXhQWVdoZlVuRXhhMEZJV21abE1VOUxiWEpUZUVSU1pUZEphMGx2TlcxUlIzVnIuXzJpNUhBY2tGaDVsc2Z1d0tnRFdCb1V2dEx5clFjOUJQazZrUnVhUlZROA=='

const apiJwt = axios.create({
  // baseURL: 'https://api-v2.todasgroup.com.br/api/v1',
  // baseURL: 'https://ac8d-2804-868-d041-2bbd-884e-b82e-48a3-7fe1.ngrok-free.app/api/v1/',
  // baseURL: 'http://localhost/api/v1/',
  baseURL: 'https://api-v2-production-node.todasgroup.com.br/api/v1',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Basic ${API_TOKEN}`
  }
})

export default apiJwt
