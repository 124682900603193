export default {
  translations: {
    profile: {
      perfil: 'Perfil',
      me: 'Sobre mi',
      notes: 'Los grados',
      watch: 'Contenido',
      todas: 'TODAS GROUP',
      out: 'Salir',
      about: 'Sobre mi',
      about_me: 'Mis datos',
      edit: 'Editar perfil',
      signature: 'Mi firma',
      password: 'Cambiar contraseña',
      title1: '¿Cómo te gustaría que te llamaran?',
      title2: 'Nombre completo',
      title3: 'Correo electrónico',
      title4: 'Fecha de nacimiento',
      title5: 'Teléfono móvil',
      title6: 'Informaciones complementarias',
      title7: 'Profesión',
      title8: 'Objetivo en TODAS GROUP',
      title9: 'Mi dirección',
      title10: 'CEP',
      title11: 'Camino',
      title12: 'Complementar',
      title13: 'Vecindario',
      title14: 'Ciudad',
      title15: 'Estado',
      title16: 'País',
      title17: 'Guardar',
      title18: 'Ahorro'
    },
    profile_types: {
      title1: 'Alumno',
      title2: 'Empreendedora',
      title3: 'Corporativo',
      title4: 'Autônoma',
      title5: 'En transición',
      title6: 'Jurídico',
      title7: 'Área de la salud'
    },
    profile_password: {
      title1: 'Detalles de suscripción',
      password: 'Contraseña',
      confirmPassword: 'Confirmar seña'
    },
    components: {
      search: 'Pesquisar',
      new_content: 'Nuevo'

    },
    login_screen: {
      email: 'Correo',
      password: 'Contraseña',
      forgot_password: 'Olvidaste tu contraseña?',
      message: 'Ya eres parte de Todas Group y es tu primera vez en la nueva plataforma?',
      update_password: 'Actualiza tu contraseña',
      login: 'Iniciar sesión',
      login_load: 'Entrando',
      login_microsoft: 'Iniciar sesión con Microsoft AD',
      register: 'Aun no tiene una cuenta?',
      make_part: 'Fazer parte'
    },
    footer: {
      copyright: 'Todos los derechos reservados.',
      home: {
        title: 'SOS Todas Juntas',
        description: 'Envía tu pregunta a uno de nuestros expertos y recibe orientación personalizada en 48 horas.',
        details: 'Habla con un experto'
      }
    },
    header_logged: {
      welcome: 'Bienvenido a TODAS',
      home: 'Comenzar',
      habilidades: 'Habilidades',
      conquistas: 'Logros',
      potencias: 'Tus Fortalezas'
    },
    conquest_main: {
      parabens: 'Felicidades',
      parabens_desc: 'Tus logros son increibles',
      desc: '¡Qué fantástica evolución!',
      desc_02: 'Estábamos muy orgullosos',
      desc_03: 'Seguir creciendo yendo cada vez más lejos',
      obj: 'Ver contenido',
      obj_02: 'Hacer Network',
      obj_03: 'Ver progreso'
    },
    conquest_internal: {
      gift: 'ver premio',
      shared: 'Compartir',
      start: 'Comenzar',
      unblock: 'Desbloquear nuevos sellos y premios',
      content_watch: 'contenido visto',
      conquest: 'Logro actual',
      progress: 'estoy progresando',
      gift_name: 'Premio',
      title_desc: 'Su constante evolución es muy importante para el GRUPO Todas. Con cada insignia especial que obtienes, ganas un premio y fortaleces aún más a toda nuestra comunidad de usuarios.',
      award_01: 'Clase 01 - Autoestima para el posicionamiento',
      award_02: 'Haz clic aquí para acceder a tu premio'
    },
    watch: {
      watch: 'Ver ahora',
      watch_now: 'Ver clases ahora',
      keep_watching: 'Sigue mirando',
      keep_description: 'Juntos evolucionando',
      watch_live: 'En Vivo',
      live_description: 'Nuestros mentores sin cortes ni filtros'

    },
    skills: {
      trail: 'Rutas de habilidades',
      title_01: 'La ciencia de la autoconfianza',
      title_02: 'Posicionamiento estratégico',
      title_03: 'Alto rendimiento emocional',
      title_04: 'Enfoque y atención plena',
      title_05: 'Autoridad y protagonismo',
      title_06: 'Adaptabilidad',
      title_07: 'Disciplina positiva',
      title_08: 'Negociación e influencia',
      title_09: 'Pensamiento crítico',
      title_10: 'Marca personal',
      title_11: 'Innovación',
      title_12: 'Liderazgo',
      title_13: 'Networking',
      title_14: 'Comunicación Estratégica',
      title_15: 'Maternidad y carrera',
      title_16: 'Desarrollo de equipo',
      title_17: 'Comunicación',
      title_18: 'Negociación - Camila Farani y Paula Paschoal',
      title_19: 'Equilibrio Emocional',
      title_20: 'El posicionamiento está presente',
      title_21: 'Aprendizajes emprendedores',
      title_22: 'Consejos de liderazgo',
      title_23: 'Cómo ser CEO de tu propia carrera',
      title_24: 'Pilares para construir tu marca',
      title_25: 'Creando tu espacio',
      title_26: 'Decisiones valientes',
      title_27: 'Identidades y aprendizaje',
      title_28: 'Construyendo una carrera más allá de la norma',
      title_29: 'Mujeres en la cima',
      title_30: 'Posibilidades de carrera',
      title_31: 'Opciones que marcan la diferencia',
      title_32: 'Liderazgo con autenticidad',
      title_33: 'El coraje de rendirse a lo nuevo',
      title_34: 'Emprender con propósito',
      title_35: 'Liderazgo Humanizado',
      title_36: 'Mentalidad para crecer',
      title_37: 'Cómo crear relevancia para tu marca',
      title_38: 'Liderar en tiempos de crisis',
      title_39: 'Carrera, viaje y visión del mundo.',
      title_40: '10 lecciones para tener éxito',
      title_41: 'Marketing y propósito',
      title_42: 'Puntos esenciales en los negocios innovadores',
      title_43: 'Construyendo su marca en tiempos de crisis',
      title_44: 'Posicionamiento',
      title_45: 'Inteligencia emocional',
      title_46: 'Confianza en uno mismo',

      title_47: '',
      details_01: 'Linkedin Top Asesor de Voz y Negocios',
      details_02: 'Consultora de Diversidad',
      details_03: 'Director de Recursos Humanos Diageo y TedXSpeaker',
      details_04: 'Director Comercial Cielo',
      details_05: 'Psiquiatra especialista en estilo de vida',
      details_06: 'Head de MKT Tik Tok Am. Latina'
    },
    watch_content: {
      watch01: 'Asistidos',
      watch02: 'Em andamento',
      watch03: 'Salvas',
      watch04: 'Sin contenido',
      watch05: 'apreciado',
      watch06: 'Empieza a mirar ahora',
      watch07: 'Contenido recomendado',
      watch08: 'Descripción',
      watch09: 'Hacer Network',
      watch10: 'Crear notas ahora',
      watch11: 'Lista de notas',
      watch12: 'Salva',
      watch13: 'Ver despues',
      watch14: 'Metodología',
      watch15: 'Usted esta en',
      watch16: 'apreciado',
      watch17: 'Evaluar',
      watch18: 'Me gusta',
      watch19: '¿Cuál es el perfil ideal para la Red de este contenido?',
      watch20: 'Hola, estamos buscando perfiles que hayan completado esta clase, tan pronto como completemos las comprobaciones, te enviaremos una notificación.',
      watch21: 'Mujeres',
      watch22: 'Enviar petición',
      watch23: 'Su solicitud a la Red está pendiente de respuesta',
      watch24: 'Buscamos el perfil ideal para esta Red',
      watch25: 'Centro de mensajes',
      watch26: 'Tienes una solicitud a la Red esperando respuesta',
      watch27: 'Tienes una red activa para este contenido',
      watch28: 'Crear nota',
      watch29: 'Hora seleccionada',
      watch30: 'Seleccione una etiqueta',
      watch31: 'Título de la nota ...',
      watch32: 'Escribe aquí ...',
      watch33: 'guardar nota',
      watch34: 'Notas',
      watch35: 'Ahorrar',
      watch36: 'Seleccione una nota',
      watch37: 'Complete el campo de comentarios',
      watch38: 'Comentarios',
      watch39: 'Más reciente',
      watch40: 'Más antiguo',
      watch41: 'Escribir un comentario',
      watch42: 'Ver más',
      watch43: 'comentarios',
      watch44: 'comentario',
      watch45: '',
      watch46: '',
      watch47: '',
      watch48: '',
      watch49: '',
      watch50: ''

    },
    video_header_title: {
      title1: 'Maternidad y carrera',
      title2: 'Disciplina Positiva',
      title3: 'NetWorking',
      title4: 'Desarrollo de equipo',
      title5: 'Comunicación Estratégica'
    },
    video_job_title: {
      title1: 'Cofundador Grupo Todas',
      title2: 'Fondo. B2Mamy',
      title3: 'Neurocientífico'
    },
    video_title: {
      title01: 'Joana - Clase 01',
      title02: 'Lo esencial para el desarrollo del equipo, con la CFO de Gerardo Falcões, Julia Machado',
      title03: '“Conciencia, preparación y conversaciones difíciles. ¿Cómo afrontar los retos del liderazgo de equipos?”, por la consultora, mentora y conferenciante Marisa Salgado',
      title04: 'Autoconocimiento y confianza en uno mismo para desarrollar equipos - Servirse a uno mismo antes de servir a los demás, por la consultora, mentora y conferenciante Marisa Salgado',
      title05: 'Ejercicio de Comunicación Asertiva, con el neurocientífico Drª Carla Tieppo, faculty da Singularity University Brazil',
      title06: 'El papel de la neurociencia en la comunicación, con el neurocientífico Drª Carla Tieppo, faculty da Singularity University Brazil',
      title07: 'Ejercicio de Disciplina con la coach de carrera, Lívia Kawano',
      title08: 'Disciplina positiva y progreso constante en el tiempo, con Manoela Mitchell, CEO y Cofundadora de Pipo Saúde',
      title09: 'La importancia de la constancia para construir una vida más disciplinada, con Andrezza Zago, directora de marketing de BMW Group Financial Services',
      title10: 'La disciplina como forma de construir el amor propio, con Andrezza Zago, directora de marketing de BMW Group Financial Services',
      title11: 'Ejercicio de disciplina positiva',
      title12: 'El poder de la disciplina como medio para resolver problemas de forma más liviana, con la neurocientífica Draª Carla Tieppo, profesora de Singularity University Brasil',
      title13: 'De madre a hija y de madre a madre con las mentoras de Todas',
      title14: 'La importancia de la red de apoyo, con Marcela Rezende, Vicepresidenta de Marketing de MadeiraMadeira',
      title15: 'Maternidad y empatía, con Marcela Rezende, vicepresidenta de Marketing de MadeiraMadeira',
      title16: 'Ser madre feliz y profesional, con Luciana Viegas, creadora del Movimiento Vidas Negras Com Deficiência Importam',
      title17: 'Maternidad y transformación de vida, con Luciana Viegas, creadora del Movimiento Vidas Negras Com Deficiência Importam',
      title18: 'El poder de ser madre, con Dhafyni Mendes, cofundadora de Todas Group',
      title19: 'Resolución dentro de la maternidad, con Dhafyni Mendes, Cofundadora de Todas Group',
      title20: 'Maternidades plurales, con Dani Junco, CEO y Fundador de B2Mamy',
      title21: '¿Cómo afronta nuestro cerebro los cambios que nos rodean?',
      title22: 'Adaptabilidad cognitiva y emocional con Kim Farrell - Directora General y Marketing TikTok Latinoamérica.',
      title23: '10 consejos prácticos para la Adaptabilidad con Kim Farrell - Directora General y Marketing TikTok Latinoamérica.',
      title24: 'Todos los consejos de adaptabilidad',
      title25: 'La importancia de la Adaptabilidad hoy, con Lívia Kawano - Especialista en Desarrollo Profesional',
      title26: 'Adaptándonos a nuevos entornos, con Camila Velzi - Head of E-commerce D2C Mondelez Internacional',
      title27: 'Descubriendo nuevos caminos y posibilidades con Dilma Campos - CEO y fundadora de Outra Praia (Live Marketing)',
      title28: 'Nuevos caminos con Rachel Muller - Directora Ejecutiva de Negocios de Nestlé.',
      title29: 'Introducción',
      title30: 'Autoconfianza - Serotonina y Validación - Lección 1',
      title31: 'Autoconfianza - Propósito y Poder - Clase 2',
      title32: 'Camila Farani - 5 puntos esenciales',
      title33: 'Autoconocimiento - Clase 1',
      title34: 'Autoconocimiento - Clase 2',
      title35: 'Inteligencias Múltiples - Clase 3',
      title36: 'Autoimagen - Clase 4',
      title37: 'Autocrítica - Clase 5',
      title38: 'Autoreconocimiento - Clase 6',
      title39: '¿Cómo podemos posicionarnos asertivamente para lograr nuestros objetivos?',
      title40: 'Desarrollando un círculo virtuoso para el Posicionamiento',
      title41: 'Posicionamiento en la práctica con Gabriela Prioli - Presentadora CNN',
      title42: 'Autoestima para posicionarse, con Vivi Duarte - D… de conexiones para Meta/Facebook en Latinoamérica',
      title43: 'Planificación del posicionamiento: técnicas y métodos para elaborar tu plan de acción.',
      title44: '¡Dominio y preparación!',
      title45: 'Comunicación no verbal para posicionamiento con Luciana Guimarães - Emprendedora Serial Endeavor',
      title46: 'Comunicación verbal para posicionamiento con la profesora de oratoria Mª Peroni.',
      title47: 'Protagonismo en el posicionamiento con Denise Paludetts, especialista en autogestión profesional!',
      title48: 'Gestión emocional',
      title49: 'Sistema emocional y anticipación.',
      title50: 'Equilibrio emocional con la psiquiatra de estilo de vida Draº Ana Paula Carvalho',
      title51: '¿Qué es y dónde está el bienestar?',
      title52: 'Autogestión de factores internos',
      title53: 'Rueda de la vida',
      title54: 'Estado de flujo',
      title55: 'Conclusión',
      title56: 'Clase 01 - Dra. Carla Tieppo - Enfoque y atención plena',
      title57: 'Clase 02 - Dra. Carla Tieppo - Enfoque y Mindfulness',
      title58: 'Clase 01 - Enfoque y Atención',
      title59: 'Clase 02 - Enfoque y Atención',
      title61: 'Clase 01 - Enfoque y Conciencia',
      title62: 'Clase 02 - Manejo del estrés y la respiración',
      title63: 'Clase 03 - Organización con Plena Atención',
      title64: 'Clase 04 - Empatía y Comunicación Consciente',
      title65: 'Clase 05 - Vivir con Plena Atención',
      title66: 'Meditación 01 - Tres respiraciones',
      title67: 'Meditación 02 - Respiración Consciente',
      title68: 'Meditación 03 - Escaneo corporal',
      title69: 'Meditación 04 - Generando conexión con los demás',
      title70: 'Meditación 05 - Gratitud',
      title71: 'Deconstruyendo el concepto de Autoridad con la neurocientífica Dra. Carla Tieppo',
      title72: 'Consejos de protagonismo con la neurocientífica Dra. Carla Tieppo',
      title73: 'Buscando referentes para construir nuestra autoría con la periodista Alexandra Loras',
      title74: 'Autoconocimiento para desarrollar Autoría y Protagonismo con la periodista Alexandra Loras',
      title75: 'Cómo superar las adversidades en el camino en busca de autoría y protagonismo con la gerente de educación del Grupo Boticário, Liz Camargo',
      title76: 'Prueba de autoridad',
      title77: 'Comprender los resultados de la prueba de autoría',
      title78: '¡Un paso más en Autoría con Dicas de Todas!',
      title79: '¡3 consejos inspiradores de todos los mentores! - Ana Laura Magalhães (Forbes menores de 30 años), Flávia Bittencourt (CEO Adidas Brasil) y Suzan Rivetti (Presidenta del Grupo Johnson & Johnson)',
      title80: 'Ana K. Clase 01',
      title81: 'Ana K. Clase 02',
      title82: 'Construyendo una disciplina más positiva con pequeños actos, con Andrezza Zago, directora de marketing de BMW Group Financial Services',
      title83: 'Disciplina positiva en la práctica, con los mentores de Todas',
      title84: 'Negociar utilizando la emocionalidad, con la neurocientífica Dra. Carla Tieppo, faculty da Singularity University Brazil',
      title85: 'Ejercicio de negociación con la neurocientífica Dra. Carla Tieppo, faculty da Singularity University Brazil',
      title86: 'Experiencias de negociación con la Directora Comercial de Cielo, Renata Daltro',
      title87: 'Negociar es para Todas! Consejos de Negociación con las Mentoras de Todas',
      title88: '4 ejemplos de Negociación con la Directora Ejecutiva de Goldman Sachs, Carolina Ragazzi',
      title89: 'Gestión de emociones y autoconocimiento para negociar con la directora ejecutiva de Goldman Sachs, Carolina Ragazzi',
      title90: 'Negociación con enfoque en las personas, con la Gerente Comercial de Suzano S.A, Deidi Souza',
      title91: 'Charla sobre Negociación con la Directora Global de Google Pay, Paula Paschoal y la Fundadora de G2 Capital, Camila Farani',
      title92: 'Pensamiento crítico a la luz de la neurociencia',
      title93: 'Construyendo pensamiento crítico para la transformación social con la psicóloga Mafoane Odara',
      title94: 'Diálogos internos para desarrollar el Pensamiento Crítico con la psicóloga Mafoane Odara',
      title95: 'Métodos de Pensamiento Crítico con Gabriela Costa, Directora Senior de AB Inbev, en Nueva York',
      title96: 'La importancia de estar informado para desarrollar el Pensamiento Crítico con Alessandra Blanco, directora general de Yahoo Brasil',
      title97: 'Ejemplos y métodos para el Pensamiento Crítico con Alessandra Blanco, directora general de Yahoo Brasil',
      title98: 'Consejos para pensar críticamente desde la autoestima con la actriz y activista Tathi Piancastelli',
      title99: 'Estudios y prácticas sobre Pensamiento Crítico',
      title100: 'Sesión de desarrollo del Pensamiento Crítico con la terapeuta Gabriela Sayago',
      title101: 'Intercambios comunicativos con diferentes personas, con la terapeuta Gabriela Sayago',
      title102: '5 pasos para mejorar tu Pensamiento Crítico en el ámbito laboral con la terapeuta Gabriela Sayago',
      title103: 'Usar las percepciones de otras personas a nuestro favor, con la neurocientífica Dra. Carla Tieppo, profesora de Singularity University Brasil',
      title104: '4 pilares para desarrollar una Marca Personal, con la Dra. Carla Tieppo, profesora de Singularity University Brasil',
      title105: 'Marca Personal, ¡un nuevo tema que llegó para quedarse! Con Susana Arbex, especialista en Marca Personal',
      title106: 'Consejos prácticos sobre Marca Personal, con la especialista en Marca Personal, Susana Arbex',
      title107: 'La comunicación como aliada de la Marca Personal, con Susana Arbex, especialista en Marca Personal',
      title108: 'Prueba de Autoanálisis para mejorar tu Marca Personal',
      title109: 'Construya su marca personal con consejos de mentores  Todas',
      title110: 'Marca Personal e identidad, con la líder en Diversidad, Equidad e Inclusión, de Mondelēz International Daniela Sagaz',
      title111: 'Marca Personal como legado, con el líder en Diversidad, Equidad e Inclusión, de Mondelēz Internacional Daniela Sagaz',
      title112: 'Experiencias para la construcción de Marca Personal, con Tamara Braga, Gerente de Salud Mental, CEO y Fundadora de DiverCidade',
      title113: 'Resultado del Test de Autoanálisis para mejorar tu Marca Personal',
      title114: 'La innovación es para Todas?',
      title115: 'Mirando hacia el futuro - con Monica Magalhaes, especialista en Innovación Disruptiva',
      title116: 'Principales barreras a la Innovación en las empresas',
      title117: 'Encontrar nuevas soluciones e innovaciones inesperadas. - con el cofundador de Todas Group Dhafyni Mendes',
      title118: 'Inov(Acción) en la práctica, con el cofundador de Todas Group Dhafyni Mendes',
      title119: 'Construcción de nuevos futuros, con la Head of Future Beverages de Ambev, Nathália Brandão.',
      title120: 'Métodos para la planificación estratégica en Innovación, con la Responsable de Bebidas del Futuro de Ambev, Nathália Brandão.',
      title121: '5 ejercicios de innovación con la Dra. Carla Tieppo, Neurocientífica',
      title122: 'Estudios y prácticas sobre liderazgo con la neurocientífica Dra. Carla Tieppo, profesora de Singularity University Brasil',
      title123: 'Prueba de Liderazgo con la neurocientífica Dra. Carla Tieppo, profesora de Singularity University Brasil',
      title124: 'Perspectivas sobre liderazgo con la directora general de Dior Brasil, Daniela Ota',
      title125: 'Ampliando nuestra mirada sobre el liderazgo y la importancia del liderazgo femenino con Thiago Coelho, vicepresidente comercial de Iguatemi S.A.',
      title126: 'Viendo privilegios y escenarios cambiantes con Thiago Coelho, vicepresidente comercial de Iguatemi S.A.',
      title127: 'Perfiles de liderazgo con coach de liderazgo y autora, Caroline Marcon',
      title128: 'Inteligencia emocional para liderar con la coach líder, Caroline Marcon',
      title129: 'El liderazgo como herramienta para impulsar carreras, con la presidenta de Match Group, Eugênia Del Vigna',
      title130: 'Consejos prácticos para ejercitar nuestras habilidades de liderazgo con la presidenta de Match Group, Eugênia Del Vigna',
      title131: 'Relaciones sociales y cómo nuestro cerebro nos ayuda a establecer contactos',
      title132: 'Descubre la historia de la Head of Marketing de Adobe Latinoamérica, Núbia Mota!',
      title133: '¡Consejos y secretos de Núbia Mota para un buen Networking!',
      title134: 'Joana - Clase 02',
      title135: 'Trabajando estratégicamente tus posibilidades - Con Tati Oliva, CEO Cross Networking.',
      title136: 'Métodos para destacar en Networking con la CEO de Cross Networking - Tati Oliva',
      title137: 'Prácticas y estudios de networking.',
      title138: '“Comunicar no es sólo hablar”, con la líder de Diversidad e Inclusión, Simone Coutinho',
      title139: 'Conectando con el equipo mediante la comunicación asertiva, con la Líder de Diversidad e Inclusión, Simone Coutinho',
      title140: 'Consejos de comunicación asertiva con mentores de Todas',
      title141: 'Comunicación asertiva con la herramienta Comunicación No Violenta, por la Terapeuta Gabriela Sayago',
      title142: 'Test - ¿Cuál es tu perfil comunicativo?',
      title143: 'La preparación como elemento clave para comunicar mejor, con la periodista Mariana Guidolini',
      title144: 'Tres consejos prácticos para desarrollar una buena comunicación y oratoria, con la periodista Mariana Guidolini',
      title145: 'Usando palabras correctas para comunicar, con la periodista Mariana Guidolini',
      title146: 'Construyendo confianza en uno mismo para una comunicación más asertiva, con la periodista Mariana Guidolini',
      title147: 'Información sobre la dinámica del sistema cerebral durante la maternidad, con la neurocientífica Dra. Carla Tieppo, profesora de Singularity University Brasil',
      title148: 'La maternidad y las dinámicas construidas en relación con la carrera de las madres, con la neurocientífica Draª Carla Tieppo, profesora de la Singularity University Brazil',
      title149: 'Maternidad y ascendencia, con Gabriela Ferreira, directora de Diversidad e Inclusión de Serasa Experian',
      title150: 'Desafíos de la maternidad y la carrera, con Gabriela Ferreira, directora de Diversidad e Inclusión de Serasa Experian',
      title151: 'La importancia de elegir alianzas dentro de la maternidad, con Gabriela Ferreira, directora de Diversidad e Inclusión de Serasa Experian',
      title152: 'Datos sobre trabajo y maternidad con Dani Junco, CEO y Fundador de B2mamy',
      title153: 'Superpoderes desarrollados en la maternidad, con Dani Junco, CEO y Fundador de B2 Mamy',
      title154: 'Qué NO hacer para el desarrollo de equipos, con la neurocientífica Dra. Carla Tieppo, profesora de Singularity University Brasil',
      title155: 'Ejercicio de adherencia para desarrollar equipos, con la neurocientífica Dra. Carla Tieppo, profesora de Singularity University Brasil',
      title156: '“¿Cómo liderar y conectar con tu equipo?”, con la vicepresidenta de la Bolsa de Valores de Brasil, llamada [B]³, Ana Buchaim',
      title157: '“Mujeres y diversidad en el liderazgo para mejores resultados”, con la vicepresidenta de la Bolsa de Valores de Brasil, llamada [B]³, Ana Buchaim.',
      title158: 'Consejos para el desarrollo de equipos con mentoras de Todas!',
      title159: 'Networking estratégico para las carreras de las mujeres, con Adriana Alves, Top Voice de LinkedIn y cofundadora de Lupa!',
      title160: 'Mucho más que un currículum, utiliza LinkedIn estratégicamente para avanzar en tu carrera, ¡con Adriana Alves!',
      title161: 'Conexiones Genuinas: La Clave del Éxito, con Marisa Salgado, Top Voice y RRHH de LinkedIn!'
    },
    video_config: {
      config01: 'Velocidad de reproducción',
      config02: 'Opciones',
      config03: 'Normal'
    },
    description_skills: {
      title1: 'Mente',
      title2: 'Modelado',
      title3: 'Metodología',
      sub1: 'Comprender cómo reacciona tu cerebro a esta habilidad',
      sub2: 'Aprendiendo de la experiencia de una mujer referente en esta habilidad',
      sub3:
        'Paso a paso para desarrollar esta habilidad en ti mismo',
      sub4: '<p>La confianza en uno mismo es la base principal para tu crecimiento profesional y personal, por eso esta es nuestra primera habilidad a desarrollar de manera práctica en Todas! Aprenda a través de la neurociencia, la experiencia de Camila Farani, la mayor inversora de Brasil y una metodología innovadora elaborada por grandes expertos en desarrollo humano, ¡cómo construir definitivamente una vida más segura de sí mismo! ¿Vamos juntos?</p>',
      sub5: '<p>¡Durante 5 meses, estudiamos a 40 mujeres en puestos destacados en América Latina y mapeamos qué habilidades y puntos eran comunes a todas ellas para su éxito profesional! Un buen posicionamiento fue uno de los factores más citados en esta investigación, destacando cómo es necesario desarrollar esta habilidad. esencial para las mujeres que quieren crecer! ¡Aprende a posicionarte de manera asertiva y estratégica con las técnicas, métodos y ejemplos del equipo de Todas y mujeres que son referentes en posicionamiento, transmitiendo sus principales aprendizajes en insights para ti!\r\n\r n<p><strong>En este módulo:</strong></p>\r\n\r\n<p>Clase 1 y 2: Posicionamiento en neurociencia con un neurocientífico y facultad de Singularity: Dra. Carla Tieppo</p >\r\n\r\n<p>Clase 3: Posicionamiento en la práctica con Gabriela Prioli - presentadora de CNN</p>\r\n\r \n<p>Clase 4: Autoestima para el posicionamiento con Vivi Duarte - Director de conexiones de Meta/Facebook en Latinoamérica</p>\r\n\r\n<p>Clase 5: Planificación del posicionamiento: técnica y métodos para armar tu plan de acción!</p> \r\n\r\n<p>Clase 6: Dominio y Preparación: ¡Cómo ejecutar tu plan de posicionamiento!</p>\r\n\r\n<p>Clase 7: Comunicación no verbal para posicionamiento con Luciana Guimar&atilde;es- Serial Endeavor Entrepreneur\r\n\r\n<p>Clase 8: Comunicación verbal para colocación con profesora de oratoria M&ordf; Peroni.</p>\r\n\r\n<p>Clase 9: Protagonismo en posicionamiento con Denise Paludetto- Directora de RRHH y mentora de líderes, especialista en autogestión de carrera!</p> \r\n\r\n<p><strong>¡Disfruta tu momento de crecimiento!</strong></p>',
      sub6: `<p>Consulte las experiencias y los consejos de Nicole Vendramini para desarrollar hábitos más saludables basados en la concentración y la atención plena.
      Nicole es cofundadora y codirectora ejecutiva de la plataforma de salud y bienestar Holistix.
      Después de muchos años trabajando como especialista en marketing dentro de las principales marcas de la industria, Nicole decidió profundizar en los estudios holísticos de salud y aplicar sus conocimientos desarrollando los productos de su empresa. ¡Ahora ella te transmite todos sus consejos!</strong></p>`,
      sub7: '<p>¿Existe una fórmula adecuada para la autoría? Ser autor y situarse como protagonista de nuestra propia historia también conlleva una serie de retos. Cómo lidiar con la frustración, proyectos que no funcionan y dar pasos hacia atrás pueden suceder en el camino.</p>'
    },

    summary: {
      title1: `<p>¡Comprende en esta clase qué produce confianza en ti mismo en tu cerebro y qué forma de pensar y actuar mejora o disminuye tu confianza en ti mismo!</p>\r\n' +
      '\r\n' +
      '<p>¡Aprende de la Dra. Carla Tieppo, pionera en el estudio del cerebro en el comportamiento humano y referente en neurociencia en América Latina! Carla forma parte del cuerpo docente en Brasil de Singularity, el centro de innovación más grande del mundo, fundado en el campo de investigación de la NASA. ¡Disfruta de tu clase!</p>`
    },

    assessment: {
      title1: 'Encuesta de impacto',
      title2: 'Paso imprescindible para que puedas identificar claramente tus puntos fuertes y descubrir oportunidades de mejora,',
      title3: ' sólo te llevará 3 minutos de tu tiempo.',
      title4: 'Vamos a empezar',
      title5: 'Tiempo estimado 3 minutos',
      title6: 'ADVERTENCIA: Responda las siguientes preguntas. Esta es una encuesta completamente anónima.',
      title7: 'Analiza las siguientes frases y selecciona según los tipos de escala:',
      title8: '(1) Rara vez, (2) A veces, (3) Neutral, (4) Con frecuencia, (5) A menudo',
      title9: 'No seleccionaste todas las preguntas',
      title10: 'Respuestas guardadas exitosamente ',
      title11: 'Encuesta de potencia',
      title12: 'Este proceso ofrece la oportunidad única de explorar sus habilidades y talentos específicos, lo que resultará en la personalización de su perfil con metas y objetivos.',
      title13: ' Sólo te llevará 5 minutos de tu tiempo.',
      title14: 'Tiempo estimado 5 minutos',
      title15: '¿Cuánto te identificas con esta frase?',
      title16: 'Resultado del perfil',
      title17: 'Al reconocer nuestras habilidades y talentos, podemos dirigir esfuerzos a áreas donde somos mejores, mientras que las oportunidades de mejora nos impulsan a crecer y mejorar las habilidades técnicas.',
      title18: 'Complete la encuesta de impacto y poder para ver sus resultados.',
      title19: 'comprueba tu resultado',
      title20: 'HABILIDADES DE PODER',
      title21: 'ANÁLISIS DE HABILIDADES',
      title22: 'Comenzar',
      assessmentButton: 'Finalizar'
    },
    assessment_question: {
      info1: 'Casi nunca',
      info2: 'A menudo',
      info3: 'Finalizar',
      info4: 'A veces',
      info5: 'Neutral',
      info6: 'Con frecuencia.',
      title1: 'Me siento seguro/capaz de asumir nuevos retos.',
      title2: 'Conozco mis puntos fuertes.',
      title3: 'Puedo expresar mis ideas con claridad.',
      title4: 'Puedo equilibrar mi vida personal y profesional.',
      title5: 'Estoy seguro de que puedo llegar más lejos.',
      title6: 'Noto que las personas que me rodean notan cambios positivos en mi postura y actitudes.',
      title7: 'Creo que ya he inspirado a otras mujeres a crecer también.',
      title8: 'Me doy cuenta de que la empresa donde trabajo es un buen lugar para que las mujeres crezcan.',
      title9: 'Veo que en mi empresa las mujeres tienen las mismas oportunidades que los hombres.',
      title10: 'Mi jefe directo apoya mi crecimiento.',
      title11: 'La alta dirección de la empresa apoya el crecimiento de las mujeres.',
      title12: 'Siento un sentido de comunidad entre las mujeres en la empresa donde trabajo.',
      title13: 'Acepto tomar riesgos calculados para nuevos proyectos.',
      title14: 'Acepto imprevistos y los convierto en oportunidades.',
      title15: 'Creo que llegaré a donde quiero, independientemente de los no que reciba en el camino.',
      title16: 'Conozco mis desencadenantes emocionales y considero mis propias reacciones.',
      title17: 'Puedo ver problemas complejos y traducirlos de forma sencilla.',
      title18: 'Puedo establecer relaciones duraderas y de beneficio mutuo.',
      title19: 'Puedo influir en las decisiones que me rodean.',
      title20: 'Puedo comprometerme de principio a fin en las actividades que propongo.',
      title21: 'Creo conexión y confianza de forma rápida y asertiva.',
      title22: 'Tengo una visión clara e impulso al equipo para lograrla con éxito.',
      title23: 'Entiendo las necesidades individuales de cada miembro del equipo y puedo apoyarlos.',
      title24: 'Entiendo el valor de ser consistente en mis acciones.',
      title25: 'Estoy abierto a considerar que existen diferentes puntos de vista sobre un mismo desafío.',
      title26: 'Estoy abierto a ceder y tengo clara mi flexibilidad.',
      title27: 'Estoy genuinamente interesado en el punto de vista de la otra persona.',
      title28: 'Me siento capaz de tomar decisiones de forma individual y basada en mi propio análisis.',
      title29: 'Me siento cómodo expresando mi opinión de forma clara y firme, independientemente de las opiniones de los demás.',
      title30: 'Reconozco mis errores y puedo cambiar mi actitud hacia ellos.',
      title31: 'Reconozco mis límites y sé cuándo pedir ayuda.',
      title32: 'Reconozco el valor de mis talentos para lograr resultados profesionales.',
      title33: 'Reconozco que existen múltiples soluciones para un mismo problema.',
      title34: 'Respeto mi propio estilo para establecer un modelo de trabajo.',
      title35: 'Sé cómo equilibrar demandas urgentes e importantes.',
      title36: 'Sé dar el primer paso para conectar con otras personas para intercambios profesionales.',
      title37: 'Sé elegir el momento oportuno para posicionarme o dejarlo pasar.',
      title38: 'Conozco mis puntos fuertes y puedo utilizarlos a mi favor.',
      title39: 'Voy con la mejor idea aunque no sea mía.',
      title40: 'Soy capaz de aportar nuevas soluciones a un problema que ya ha sido discutido.',
      title41: 'Soy consciente de que todo en mí comunica y puedo ajustarme según la necesidad',
      title42: 'Me resulta fácil aprender nuevas habilidades y ponerlas en práctica.',
      title43: 'Tengo una lectura completa de la situación e influyo positivamente en el entorno y los resultados.',
      title44: 'Estoy orgulloso de mi trayectoria.',
      title45: 'Tengo una convicción inquebrantable de que lograré lo que quiero.',
      title46: 'Puedo crear narrativas atractivas que conectan mi mensaje con los objetivos estratégicos.',
      title47: 'Adapto la comunicación y su contenido de manera eficiente según el público objetivo.',
      title48: 'Soy capaz de persuadir e influenciar a otros a través de mi comunicación.'
    },

    assessment_description: {
      title1: '¡Eres genial haciendo networking! Tus habilidades sociales son increíbles, al igual que tu capacidad para leer a las personas y ayudarlas con sus objetivos cuando sea necesario, y de la misma manera construir relaciones que también te brindan herramientas para encontrar ayuda cuando la necesites con tus desafíos. Tienes la capacidad de conectar personas, creando puentes y soluciones. Esta es una habilidad muy empoderadora en el crecimiento profesional. ¡Felicitaciones! Sabemos que en nuestras vidas ocupadas donde las mujeres tenemos tantas exigencias, mantener el networking es un gran desafío. Como te resulta fácil abrir conexiones, no olvides mantener la llama encendida. Organiza tu calendario para tener un espacio específico para la interacción continua con tus contactos, desde un calendario de cumpleaños o fechas importantes, hasta mensajes para ver cómo van las cosas, esto potenciará aún más tu calendario de networking y la fortaleza de tus relaciones. En nuestro módulo de networking en Todas, la clase de Núbia Motta, experta en relaciones entre empresas en América Latina, da varios consejos sobre cómo incrementar cada vez más tu networking de forma genuina y estratégica. De hecho, hacer networking entre mujeres es una de nuestras misiones aquí en Todas, así que cuenta con nosotros para crear grandes y poderosas conexiones para ti también... ¡Todas creciendo juntas!',
      title2: '¡La innovación es tu fuerza! Tienes la capacidad de ver movimientos que la mayoría de las personas aún no han notado, transformando tu visión de negocio en una poderosa, agregando perspectivas muy interesantes al escenario actual y generando transformaciones. ¡Esto es muy poderoso! Las personas innovadoras son agentes de cambio que el mundo necesita, ¡así que debes saber reconocer tu valor por ser la mujer innovadora que eres! Las personas innovadoras piensan diferente, anticipándose a tendencias y conectando puntos que todavía parecen inconexos para los demás, por eso un gran consejo para potenciar aún más tu poder de innovación es invertir en mejorar tu comunicación para que tengas herramientas para explicar claramente lo que ya te parece obvio. pero otras personas aún no pueden verlo, por lo que además de posicionarte cada vez más como un profesional visionario, ¡también unes aliados para generar cambios innovadores contigo! Un punto de atención para las personas innovadoras es que, al tener muchas buenas ideas, el enfoque de ejecución muchas veces puede volverse confuso, saber priorizar y evaluar qué idea merece ponerse en práctica en el momento y cuál quedará para más adelante. fecha. En nuestra aplicación hay una clase de innovación con nuestra cofundadora Dhafyni Mendes, quien explica un método que aprendió de Walt Disney sobre cómo elegir la mejor idea para la mejor ejecución actual, ¡lo disfrutarás! Otro consejo increíble para ti es ver a la inspiradora futurista Mônica Magalhães hablar sobre los elementos de innovación que generan el mayor valor en las empresas de todo el mundo... ¡esta clase sin duda impulsará aún más tu talento innovador para llevarte aún más alto! ',
      title3: '¡La autoridad y el protagonismo son lugares de fortaleza en tu vida! Puedes utilizar tus características personales como diferenciador para destacar, haciéndote único en el entorno en el que te encuentras. ¡Esta es una característica de los profesionales brillantes y TODOS estamos muy felices aquí cuando conocemos mujeres que ejercitan esta fuerza! En un mundo con tantos sesgos para que las mujeres "encajen" en el mercado laboral, las profesionales que se sienten orgullosas de sus diferencias y saben utilizarlas positivamente en el mercado son agentes de grandes cambios en todo el ecosistema profesional. Créeme, ¡inspiras mucho más de lo que imaginas! Las personas que te rodean que ni siquiera puedes imaginar encuentran en tu coraje para ser quien eres, una manera de ser ellos mismos también, ¡y eso es transformador! Sin embargo, la otra cara de la moneda cuando asumes con orgullo tu trayectoria y no aceptas borrarte para igualar o encajar, es que no todos entenderán tu fuerza autoral y protagónica, así que asegúrate de estar entre personas que valoran tu diferencia. , busca aliados internos en tu trabajo que vean tu brillantez y encuentren espacios donde tu talento se pueda propagar. Para motivarte, mira la clase de Autoridad y Protagonismo con Alexandra Loras en nuestra aplicación, donde ella cuenta en su viaje inspirador cómo pudo haberse dejado limitar por eventos de su vida que aprovechó para potenciar aún más su éxito, posicionándose en lugares en los que podría ejercer plenamente su ventaja. ¡El deseo de todos nosotros es que brilles cada vez más! ',
      title4: '¡El posicionamiento es tu punto fuerte! Tienes la increíble capacidad de capturar información del entorno, encontrar soluciones y presentarlas estratégicamente. Sepa que el posicionamiento es una de las principales dificultades en el crecimiento de las carreras femeninas, y si ya domina esta habilidad, su ventaja competitiva en el mercado es inmensa. La buena noticia es que el posicionamiento es como un músculo que cuanto más lo ejercitas, más fuerte se vuelve y tu punto fuerte será más extraordinario cuanto más lo uses. No pierdas oportunidades de exponer tu punto de vista en reuniones estratégicas y exponer tus resultados en contextos relevantes, y el punto de atención a tu facilidad de posicionamiento es saber elegir en qué realmente necesitas posicionarte. No desperdicies energía posicionándote en todo, uno de los grandes aspectos del posicionamiento es ser estratégico sobre el mejor momento y forma de hacerlo, en nuestra app la clase de posicionamiento estratégico de Gabriela Prioli aborda exactamente esto, ¡buen consejo para ti! ¡Abusa de tu poder de posicionamiento y conquista el mundo!',
      title5: '¡La confianza en ti mismo es tu fuerza! Eres consciente de tus fortalezas y sabes cómo utilizarlas, aportando los aprendizajes que has experimentado a lo largo de tu recorrido como herramientas internas para actuar ante los desafíos externos. Esta es una habilidad común entre las mujeres en puestos de alto liderazgo, una señal de que estás en el camino correcto para lograr tus objetivos e inspirar a más mujeres a lograr sus sueños también... ¡Felicitaciones! \n' +
        'Por otro lado, un punto de atención que excede la confianza en uno mismo es el egocentrismo, cuando te concentras tanto en tu visión que empiezas a ver menos otros puntos de vista a tu alrededor que también pueden ser partes importantes de la solución. un problema. Ejercita también tu escucha activa para que tu confianza en ti mismo se potencie cada vez más de forma positiva y amplíes cada vez más tu repertorio de aprendizajes y logros. En la clase de autoconfianza de Camila Farani en nuestra aplicación, ella, considerada una de las 500 mujeres más influyentes de América Latina, brinda excelentes consejos sobre cómo administrar tu confianza en ti misma para que puedas volar cada vez más alto. ¡TODOS lo apoyamos y esperamos aplaudir sus próximos logros, ya que estamos seguros de que logrará muchos! ',
      title6: '¡La adaptabilidad es tu punto fuerte! En un mundo que cambia tanto y tan rápidamente, ¡tu capacidad para percibir cambios y realizar cambios rápidamente para mejorar los resultados en nuevos escenarios es muy valiosa! No es casualidad que después de la pandemia la adaptabilidad se haya convertido en la habilidad más buscada en el entorno profesional, así que aprovecha este momento para resaltar tu talento. Eres sensible para captar información reciente, sagaz para pensar en posibles soluciones innovadoras y ágil para realizar nuevos intentos... ¡y eso es increíble! ¡Aprecia esta fuerza tuya! ¡Un punto de cuidado para las mujeres súper adaptables es no sobreadaptarse! ¡No pases de lo que crees adaptándote a algo con lo que no estás de acuerdo! En su clase de adaptabilidad en Todas, Rachel Muller, inspiradora ejecutiva de Nestlé, aborda precisamente este tema entre el límite saludable de la adaptabilidad, seguro que te gustará... ¡un gran consejo para potenciar aún más tu poderosa competencia que es la adaptabilidad! ¡No olvides ver también a Kim Farrel, directora general de operaciones de marketing de TikTok en América Latina, quien analiza sus 10 pasos para mejorar la adaptabilidad y volar aún más alto! ',
      title7: '¡La negociación es tu fuerza! Sabes leer escenarios y encontrar los mejores argumentos para resolver conflictos y crear buenas oportunidades. También tienes un excelente poder de persuasión e interacción, sabiendo mostrar el mejor lado de la situación, creando confianza y conexión con tu posicionamiento. Tu capacidad para escuchar, comunicar y saber cuándo necesitas ser flexible también te hace destacar en tus negociaciones. ¡Esta es una habilidad poderosa en el crecimiento profesional femenino! Un punto a prestar atención a las personas que son excelentes negociando es también prestar atención a los entregables que negoció, para que su próxima negociación con esa persona sea tan eficiente como la primera. En su clase de Negociación en Todas, Renata Daltro, VP de Cielo, quien es reconocida por su poder de negociación, habla de su camino para convertirse en un referente en un entorno aún predominantemente masculino. ¡Sin duda te inspirará a utilizar tu talento en la negociación para lograr todos los entornos que sueñas! ',
      title8: '¡La disciplina es tu punto fuerte! Tienes la capacidad de ver claramente qué puntos son necesarios para llevarte a las metas que deseas, construyendo metas estructuradas para ejecutar este escenario, asignando responsabilidad y consistencia en este proceso. ¡Felicidades! Las personas más exitosas del mundo tienen la disciplina como punto esencial de sus extraordinarios resultados, señal de que ya estás en el camino correcto para tu crecimiento. También tienes una capacidad súper positiva para organizar y priorizar tareas, valora esto y aplícalo cada vez más en tus procesos y equipos de trabajo, esto aportará cada vez más eficiencia a las operaciones en las que estés involucrado. Un punto de atención para las personas muy decididas son las exigencias excesivas, ¡no olvides ser también más amable contigo mismo en el proceso de alcanzar tus objetivos! ¡Sé amable contigo mismo! En nuestra aplicación tenemos un módulo completo sobre disciplina positiva con grandes líderes de América Latina para potenciar aún más tu capacidad, y un buen consejo para ti es también asistir a la clase de inteligencia emocional con la Dra. Ana Paula Carvalho, psiquiatra de la Escuela de Medicina de Harvard, que aborda cómo ser más amables con nosotros mismos de una manera práctica en medio de nuestro viaje de crecimiento. ¡Todos creciendo juntos!',
      title9: '¡La Inteligencia Emocional es tu poder! Tienes la capacidad de analizar los hechos y elegir la mejor manera de reaccionar, reduciendo los impactos negativos en tu vida y en las personas que te rodean, ¡y esta es una de las habilidades más valiosas de nuestra sociedad actual! Muchas veces nuestras fortalezas nos resultan tan naturales que somos incapaces de medir su valor, posiblemente tú tengas esta sabiduría natural y ni siquiera puedas imaginar el resultado positivo que genera tu presencia en tu entorno profesional y personal. Pero como la conciencia es el primer paso hacia el crecimiento, ahora que sabes que la inteligencia emocional es tu fortaleza, úsala a tu favor y valora tu fortaleza. No pierdas oportunidades para expresar tu punto de vista, ya que tienes gran visión y comprensión de las reacciones, efectos y consecuencias, utiliza tu sabiduría para destacar. Punto de atención, como tienes una gran capacidad de comprensión emocional del entorno, cuida que las personas que te rodean no abusen de tu comprensión siendo irrespetuosos contigo y esperando tu forma tranquila de reaccionar, sobrecargándote de tareas o no reconociendo adecuadamente tus entregas y valor. Recuerda lo valioso que eres y utiliza todo tu buen discernimiento emocional para encontrar la mejor manera de que te valoren como te mereces. ¡¡Esta habilidad tuya sin duda te llevará muy lejos!! ',
      title10: '¡Vaya, tu punto fuerte es el pensamiento crítico! Tienes la increíble capacidad de recopilar información diferente, analizar puntos de vista opuestos con sensatez y encontrar elementos en diferentes escenarios para construir tus opiniones, ¡eso es muy valioso! En un mundo tan polarizado como el que vivimos donde la mayoría de las personas sólo quiere convencer a los demás de sus convicciones, una persona como tú que sabe escuchar y dialogar con diferentes posturas y está dispuesta a cambiar de opinión sobre un tema, sopesando los hechos. ...no sólo es raro, sino también muy necesario para el crecimiento de las corporaciones y la evolución de la sociedad. ¡Eres agente de los cambios que nuestro mundo necesita! Tu poder de pensamiento crítico te brinda una inmensa ventaja competitiva al sugerir soluciones, confiar en tu capacidad y expresar más tus opiniones en reuniones de trabajo, con tu liderazgo o pares. Otro punto positivo de tu fortaleza en el pensamiento crítico es contribuir a un clima organizacional positivo ya que tienes el poder de leer diferentes escenarios, pudiendo reducir conflictos e impactos en personas que piensan de manera completamente opuesta en el equipo. Mafoane Odara, reconocida líder en América Latina, conocida como constructora de puentes en ambientes de conflicto, aborda en su clase de pensamiento crítico en Todas, cómo equilibrar los lados positivos y negativos de ser mujer con pensamiento crítico y aprovechar al máximo ¡Esta magnífica habilidad ya la tienes! ¡TODOS estamos aquí apoyándote y apoyándote, porque estamos convencidos de que más personas con un alto pensamiento crítico como tú, en altos puestos de liderazgo, es el camino hacia mejores organizaciones para TODAS las personas! ',
      title11: '¡El liderazgo es tu fuerza! Tiene una sólida visión empresarial, tenacidad para construir un camino hacia su objetivo y la capacidad de crear influencia y confianza para motivar a los aliados a caminar con usted en esa dirección. Sabes inspirar, desarrollar y gestionar conflictos en equipos, encontrando soluciones y generando crecimiento. Realmente creemos que más mujeres en puestos de liderazgo mejoran el ambiente laboral para TODAS las personas, por eso contamos contigo para esta misión. Gracias por ser la mujer que eres, sabemos que no fue fácil llegar a donde estás hoy. tienes una lista enorme de grandes desafíos que has enfrentado y aún enfrentas, por eso ahora queremos pedirte que te tomes 30 segundos para respirar, ¡valorando el liderazgo que has construido dentro de ti! ¡Que ahora te sientas orgulloso de tu trayectoria y fortaleza! ¡Que este aliento renueve tu energía para llegar aún más alto, logrando todos los sueños a los que aspiras! Para inspirarte en este viaje, en nuestra aplicación tenemos grandes líderes de América Latina que comparten honestamente los desafíos que enfrentaron en su liderazgo y cómo superaron estos obstáculos, asegúrate de ver a Flávia Bittencourt: CEO Adidas América Latina, Fiama Zariffe: CEO Airbnb, Daniela Ota: CEO Dior y Beatriz Bottesi: CMO Meta América Latina, quienes entre muchas otras mujeres inspiradoras en nuestra aplicación, hablan abiertamente sobre temas con los que seguro te identificarás. ¡TODOS estamos aquí apoyando su crecimiento y éxito!',
      title12: '¡Tienes una excelente comunicación estratégica! Tu habilidad para transmitir ideas de manera clara, asertiva y empática te permite construir conexiones significativas y alcanzar tus objetivos con mayor facilidad. Sabes cómo adaptar tu mensaje al contexto y al público, garantizando que tu comunicación sea siempre efectiva e impactante. Esta es una competencia esencial para el crecimiento profesional. ¡Felicidades! Sabemos que, con la rutina intensa y las múltiples demandas que enfrentan las mujeres, mantener una comunicación alineada y estratégica puede ser un desafío. Por eso, recuerda practicar la escucha activa, estructurar bien tus mensajes y siempre considerar el impacto de tus palabras. Pequeños ajustes en el día a día pueden hacer que tu comunicación sea aún más poderosa. Por otro lado, un punto de atención en la comunicación estratégica es asegurarte de que tu mensaje sea siempre claro y empático. En algunos momentos, el exceso de planificación puede hacer que la comunicación sea más técnica y menos humanizada, dificultando conexiones genuinas. Ejercitar la escucha activa y la adaptación del lenguaje al contexto puede potenciar aún más tu influencia y garantizar que tu comunicación sea siempre asertiva e inspiradora. En nuestro módulo de Comunicación Estratégica en Todas, expertas como la Dra. Carla Tieppo y Simone Coutinho ofrecen insights valiosos para mejorar tu comunicación, desde técnicas de neurociencia hasta la comunicación no violenta. Aquí, creemos que comunicarse bien es uno de los pilares del éxito, y estamos juntas para fortalecer esta habilidad. ¿Potenciemos tu comunicación?'
    },
    assessment_opt_in: {
      title: 'Paso',
      title1: 'Volver',
      title2: 'Registro - Plan Corporativo',
      title3: 'Regístrate ahora para acceder a la plataforma y ser parte de este movimiento.',
      title4: 'Nombre',
      title5: 'Correo electrónico',
      title6: 'Recibido por correo electrónico',
      title7: 'Código de Activación',
      title8: 'Recibido por correo electrónico',
      title9: 'Número de móvil',
      title10: 'Crear una contraseña',
      title11: 'Confirmar seña',
      title12: 'Me reconozco como mujer*',
      title13: 'Acepto los Términos de Uso y Política de Privacidad*',
      title14: 'Acepto los Términos de Uso y Política de Privacidad de Todas Group y JNJ*',
      title15: 'Acepto los Términos de Uso y Política de Privacidad de Todas Group y NTT*',
      title16: ' Al registrarte, aceptas nuestra',
      title17: 'Terminos de uso',
      title18: '. Además,',
      title19: ' usted acepta el intercambio de datos con fines cartográficos',
      title20: ' datos demográficos y aceptar recibir noticias y promociones.',
      title21: 'Política de privacidad',
      title22: 'Suscribirse',
      title23: 'El campo requerido',
      title24: 'El código de activación debe tener al menos 6 dígitos.',
      title25: '¡Completa tu perfil!',
      title26: 'Complete algunos datos sobre su empresa y su puesto.',
      title27: 'Empresa',
      title28: 'vicepresidencia',
      title29: 'Seleccione su unidad de negocio',
      title30: 'Posición actual',
      title31: 'Ingresa el enlace de tu perfil',
      title32: 'Finalizar',
      title33: 'Número del documento',
      title34: 'Comience su viaje en Todas!',
      title35: 'Selecciona una opción para unirte a la plataforma',
      title36: 'Plan Corporativo',
      title37: 'Para utilizar el beneficio que ofrece su empresa',
      title38: 'Plan individual',
      title39: 'Únase a nuestra comunidad como individuo',
      title40: '¿Ya tienes una cuenta?',
      title41: '¡Bienvenidos al futuro del liderazgo femenino! ',
      title42: ' ¿Listo para impulsar tu carrera? Para hacer esto, necesitamos cierta información de usted. No se preocupe,',
      title43: 'Sólo te llevará 10 minutos de tu tiempo.',
      title44: 'Empezemos',
      title45: '',
      title46: '',
      title47: '',
      title48: '',
      title49: '',
      title50: ''

    },

    networks: {
      title1: 'Sin network',
      title2: 'No se seleccionó ningún chat',
      title3: 'has visto',
      title4: 'para asistir',
      title5: '¿Quieres ir al contenido completo de la solicitud?',
      title6: 'Ir al contenido completo',
      title7: 'Cancelar',
      title8: 'Su solicitud del network está pendiente de respuesta',
      title9: 'está solicitando a la red este contenido',
      title10: 'Buscamos el perfil ideal para esto Network',
      title11: 'Aceptar',
      title12: 'Acepté su solicitud de contenido en Network ',
      title13: 'Você aceitou a solicitação de Network de',
      title14: 'para este contenido',
      title15: 'Para comenzar Network',
      title16: 'Borrar Network',
      title17: '¿Quieres eliminar este pedido del Network?',
      title18: 'Borrar',
      title19: 'Cancelar',
      title20: 'Tu petición ha sido aceptada',
      title21: 'Esperando respuesta',
      title22: 'Solicitud de red aceptada',
      title23: 'Solicitud de red',
      title24: 'Detalles',
      title25: 'Contenido',
      title26: 'Para comenzar',
      title27: 'Aceptar',
      title28: '',
      title29: '',
      title30: ''

    },

    todas: {
      title1: 'Crear publicación',
      title2: 'Usuaria anónima',
      title3: 'respuestas',
      title4: 'Trabajo',
      title5: 'Familia',
      title6: 'Salud',
      title7: 'Ocio',
      title8: 'Acoso',
      title9: 'Título',
      title10: 'Descripción',
      title11: 'Mandar',
      title12: 'Borrar',
      title13: '¿Enviar como usuario anónimo?',
      title14: 'Sí',
      title15: 'No',
      title16: 'Selecciona el tema',
      title17: '',
      title18: '',
      title19: '',
      title20: ''
    },
    home: {
      message: '¡Hola Mundo!'
    },
    faq: {
      whatsSpan1: 'Soporte Whatsapp',
      whatsSpan2: 'Iniciar conversación por WhatsApp',
      whatsSpan3: 'Preguntas frecuentes',
      whatsTxT: '¿Intentó resolver su duda en "Preguntas frecuentes" y no encontró lo que buscaba? Póngase en contacto con nuestro soporte:',
      message: 'No hay preguntas registradas',
      title_01: 'Preguntas frecuentes',
      title_02: 'Soporte vía WhatsApp',
      question_1: '¿Qué es el Grupo Todas?',
      question_2: '¿Cómo descargo la aplicación Todas?',
      question_3: '¿Qué contenidos encontraré en la plataforma?',
      question_4: '¿Cuánto cuesta Grupo Todas?',
      question_5: '¿Los cursos están certificados?',
      question_6: '¿Cómo funciona la comunidad de mujeres?',
      question_7: '¿Cómo cancelo mi suscripción?',
      answer_1: '<p>Somos una plataforma digital que democratiza el acceso a líderes altamente inspiradores para impulsar la trayectoria profesional de las mujeres.</p>\r\n\r\n<p>La aplicación Todas fue desarrollada para proporcionar un recorrido de conocimiento. Esta es una curso sin precedentes basado en las habilidades de las mujeres de más rápido crecimiento en Brasil y el mundo.</p>\r\n\r\n<p>Las clases y ejercicios se imparten a través de una metodología innovadora, capaz de ayudarte a desarrollar una mentalidad exitosa. modelo en unas semanas.</p>\r\n\r\n<p>También contamos con una comunidad llena de mujeres para intercambiar experiencias, además de gamificación para facilitar el aprendizaje y recompensas por tu desempeño.</p>',
      answer_2: '<p><a href="https://apps.apple.com/br/app/todas-group/id1564341168" rel="noopener" target="_blank">IOS</a></p>\r\n\r\n<p><a href="https://play.google.com/store/apps/details?id=com.todasgroup&amp;hl=pt" rel="noopener" target="_blank">GooglePlay</a></p>',
      answer_3: '<p>Tendrás acceso a nuestro track de conocimientos con habilidades esenciales para tu desarrollo profesional, cada habilidad está compuesta por videoclases y ejercicios prácticos.</p>\r\n\r\n<p>No te preocupes, todo ha sido diseñado teniendo en cuenta la rutina de la mujer moderna, ¡las clases son rápidas y dinámicas!</p>\r\n\r\n<p>Además, tendrás acceso a Todas Libros con resúmenes en audio y charlas con mujeres líderes reconocidas internacionalmente.</p>',
      answer_4: '<p>Anual ver: R$ 2.600,00<br />\r\nCuotas anuales: 12 cuotas de R$ 260,00</p>',
      answer_5: '<p>Al completar cada habilidad, podrás completar cada habilidad. ¡Obtendrás un certificado relacionado con él e incluso podrás compartirlo en tu Linkedin!</p>',
      answer_6: '<p>Nuestra comunidad es conformado por mujeres diversas, con los más variados desafíos y experiencias profesionales. usted tendrá Tendrás la oportunidad de compartir dudas y desafíos, en 48 horas estarás listo. usted recibirá una respuesta.</p>',
      answer_7: '<p><strong>iOS:</strong> Para cancelar tu suscripción a través de App Store, sigue los pasos a continuación:</p>\r\n\r\n<p>1- Accede a "Configuración" en tu dispositivo</p>\r\n\r\n<p>2- Haz clic en tu nombre</p>\r\n\r\n<p>3- Haz clic en "Suscripciones"</p>\r\n\r\n<p>4- Haz clic en tu suscripción a Todas</p>\r\n\r\n<p>5- Haz clic en "Cancelar suscripción"</p>\r\n\r\n<p><strong>Android:</strong> Para cancelar tu suscripción a través de Play Store, sigue los pasos a continuación:</p>\r\n\r\n<p>1- Accede a Play Store</p>\r\n\r\n<p>2- En la esquina superior, haz clic en tu foto de perfil</p>\r\n\r\n<p>3- Haz clic en "Pagos y suscripciones"</p>\r\n\r\n<p>4- Haz clic en "Suscripciones"</p>\r\n\r\n<p>5- Selecciona tu suscripción a Todas</p>\r\n\r\n<p>6- Haz clic en "Cancelar suscripción"</p>'
    },
    PageNotFound: {
      title: 'Página no encontrada',
      p: 'Prueba nuestro otro contenido'
    },
    sign_up: {
      title1: 'Registro',
      title2: 'Nombre completo',
      title3: 'Correo electrónico',
      title4: 'Teléfono móvil',
      title5: 'Contraseña',
      title6: 'Confirmar seña',
      title7: 'Me reconozco como mujer',
      title8: 'Registrar',
      title9: 'Registrarse',
      title10: 'Atrás para iniciar sesión',
      title11: 'Las ',
      title12: 'lecciones aprendidas y las direcciones que los grandes líderes de Brasil',
      title13: 'aprendieron a lo largo de muchos años, principales especializaciones y diversas experiencias .',
      title14: '¡Ahora disponible para ti!',
      title15: '¡Tú creces, Todas crecen!',
      title16: '¡Se parte de ello!',
      title17: 'CPF',
      title18: '',
      title19: '',
      title20: ''
    },
    recovery: {
      title1: 'Olvide mi contraseña',
      title2: 'Correo electrónico',
      title3: 'Enviar código',
      title4: 'Enviando',
      title5: 'Hacer login',
      title6: 'Recuperar contraseña',
      title7: 'Código',
      title8: 'Contraseña',
      title9: 'Confirmar seña',
      title10: 'Recuperar',
      title11: 'Recuperación',
      title12: '',
      title13: '',
      title14: '',
      title15: '',
      title16: '',
      title17: 'CPF',
      title18: '',
      title19: '',
      title20: ''
    },
    formValidations: {
      ValidateRequiredField: 'Este campo es obligatorio',
      ValidateInvalidEmail: 'Correo electrónico no válido',
      ValidateMinPassword: 'La contraseña debe tener al menos 8 caracteres',
      ValidateMaxPassword: 'La contraseña debe tener como máximo 24 caracteres',
      ValidatePasswordNotEquals: 'Las contraseñas no coinciden',
      ValidateInvalidCPF: 'CPF no válido',
      ValidateInvalidCardNumber: 'Número de tarjeta no válido',
      ValidateInvalidDateExp: 'Fecha de expiración no válida',
      ValidateOnlyNumbers: 'Este campo solo acepta números',
      ValidateMinName: 'El nombre debe tener al menos 2 caracteres',
      ValidateMaxToken: 'El token debe tener como máximo 5 caracteres',
      ValidationNotFound: "Validación '{{name}}' no encontrada",
      AlertSendCode: 'Código enviado con éxito.',
      AlertUserNotVerify: 'Usuario no verificado.',
      AlertSendCodeError: 'Error al enviar el código. Por favor, inténtelo de nuevo.'
    }
  }
}
