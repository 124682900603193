export default {
  translations: {
    profile: {
      perfil: 'Perfil',
      me: 'Sobre mim',
      notes: 'Notas',
      watch: 'Conteúdos',
      todas: 'TODAS GROUP',
      out: 'Sair',
      about: 'Sobre mim',
      edit: 'Editar perfil',
      signature: 'Minha assinatura',
      password: 'Alterar senha',
      about_me: 'Meus dados',
      title1: 'Como gostaria de ser chamada?',
      title2: 'Nome completo',
      title3: 'E-mail',
      title4: 'Data de nascimento',
      title5: 'Celular',
      title6: 'Informações complementares',
      title7: 'Profissão',
      title8: 'Objetivo no TODAS GROUP',
      title9: 'Meu endereço',
      title10: 'CEP',
      title11: 'Rua',
      title12: 'Complemento',
      title13: 'Bairro',
      title14: 'Cidade',
      title15: 'Estado',
      title16: 'País',
      title17: 'Salvar',
      title18: 'Salvando'
    },
    profile_types: {
      title1: 'Estudante',
      title2: 'Empreendedora',
      title3: 'Corporativa',
      title4: 'Autônoma',
      title5: 'Em Transição',
      title6: 'Jurídico',
      title7: 'Área da saúde'
    },

    profile_password: {
      title1: 'Detalhes da assinatura',
      password: 'Senha',
      confirmPassword: 'Confirmar senha'
    },
    components: {
      search: 'Pesquisar',
      new_content: 'Novidade'

    },
    login_screen: {
      email: 'E-mail',
      password: 'Senha',
      forgot_password: 'Esqueci minha senha',
      message: 'Já faz parte do Todas Group e é a primeira vez na plataforma nova?',
      update_password: 'Atualize sua senha',
      login: 'Entrar',
      login_load: 'Entrando',
      login_microsoft: 'Entrar com o Microsoft AD',
      register: 'Ainda não tem conta?',
      make_part: 'Fazer parte',
      token: 'Token'
    },
    footer: {
      copyright: 'Todos os direitos reservados.',
      home: {
        title: 'SOS Todas Juntas',
        description: 'Mande sua dúvida para uma de nossas especialistas e receba orientação personalizada dentro de até 48 horas.',
        details: 'Falar com uma especialista'
      }
    },
    header_logged: {
      welcome: 'Bem-vinda ao TODAS',
      home: 'Início',
      habilidades: 'Habilidades',
      conquistas: 'Conquistas',
      potencias: 'Suas Potências'
    },

    conquest_main: {
      parabens: 'Parabéns',
      parabens_desc: 'Suas conquistas estão incríveis',
      desc: 'Que evolução fantástica!',
      desc_02: 'Nós ficamos muito orgulhosas',
      desc_03: 'Continue a crescer indo cada vez mais longe',
      obj: 'Assistir Conteúdos',
      obj_02: 'Fazer Network',
      obj_03: 'Ver progresso'
    },
    conquest_internal: {
      gift: 'ver prêmio',
      shared: 'Compartilhar',
      start: 'Início',
      unblock: 'Desbloquear novos selos e prêmios',
      content_watch: 'conteúdos assistidos',
      conquest: 'Conquista atual',
      progress: 'Em progresso',
      gift_name: 'Prêmio',
      title_desc: 'Sua evolução constante é muito importante para o TODAS GROUP. A cada selo especial conquistado, você ganha um prêmio e fortalece ainda mais toda nossa comunidade de usuárias.',
      award_01: 'Aula 01 - Auto estima para posicionamento',
      award_02: 'Clique aqui para acessar o seu prêmio'
    },
    watch: {
      watch: 'Assista agora',
      watch_now: 'Assista agora às aulas',
      keep_watching: 'Continue assistindo',
      keep_description: 'Juntas evoluindo',
      watch_live: 'Rolou ao vivo',
      live_description: 'Nossas mentoras sem cortes e sem filtros'
    },
    skills: {
      trail: 'Trilhas de habilidades',
      title_01: 'A ciência da autoconfiança',
      title_02: 'Posicionamento-estrategico',
      title_03: 'Alta Performance Emocional',
      title_04: 'Foco e Atenção Plena',
      title_05: 'Autoralidade e Protagonismo',
      title_06: 'Adaptabilidade',
      title_07: 'Disciplina Positiva',
      title_08: 'Negociação e Influência',
      title_09: 'Pensamento Crítico',
      title_10: 'Marca Pessoal',
      title_11: 'Inovação',
      title_12: 'Liderança',
      title_13: 'Networking',
      title_14: 'Comunicação Estratégica',
      title_15: 'Maternidade e Carreira',
      title_16: 'Desenvolvimento de Equipes',
      title_17: 'Comunicação',
      title_18: 'Negociação - Camila Farani e Paula Paschoal',
      title_19: 'Equilibrio Emocional',
      title_20: 'Posicionamento é Presente',
      title_21: 'Aprendizados de Empreendedora',
      title_22: 'Conselhos de Liderança',
      title_23: 'Como ser CEO da sua própria carreira',
      title_24: 'Pilares para construir sua marca',
      title_25: 'Criando seu espaço',
      title_26: 'Escolhas Corajosas',
      title_27: 'Identidades e Aprendizados',
      title_28: 'Construindo uma carreira além dos padrões',
      title_29: 'Mulheres no Topo',
      title_30: 'Possibilidades na carreira',
      title_31: 'Escolhas que fazem a diferença',
      title_32: 'Liderança com autenticidade',
      title_33: 'A coragem de se entregar ao novo',
      title_34: 'Empreendendo com propósito',
      title_35: 'Liderança Humanizada',
      title_36: 'Mindset para crescer',
      title_37: 'Como criar relevância para sua marca',
      title_38: 'Liderando em tempos de crise',
      title_39: 'Carreira, Jornada e Olhar do Mundo',
      title_40: '10 aprendizados para se ter sucesso',
      title_41: 'Marketing e propósito',
      title_42: 'Pontos essenciais em negócios inovadores',
      title_43: 'Construindo sua Marca em Tempos de Crise',
      title_44: 'Posicionamento',
      title_45: 'Inteligência emocional',
      title_46: 'Auto-confiança',
      title_47: '',
      details_01: 'Linkedln TopVoice e Conselheira de empresas',
      details_02: 'Consultora de Diversidade',
      details_03: 'Diretora de RH Diageo e TedXSpeaker',
      details_04: 'Dir. Comercial Cielo',
      details_05: 'Psiquiatra especialista em estilo de vida',
      details_06: 'Head de MKT Tik Tok Am. Latina'
    },
    watch_content: {
      watch01: 'Assistidos',
      watch02: 'Em andamento',
      watch03: 'Salvos',
      watch04: 'Nenhum conteúdo',
      watch05: 'curtiram',
      watch06: 'Começar assistir agora',
      watch07: 'Conteúdos recomendados',
      watch08: 'Descrição',
      watch09: 'Fazer Network',
      watch10: 'Criar Notas agora',
      watch11: 'Listar Notas',
      watch12: 'Salvo',
      watch13: 'Assistir mais tarde',
      watch14: 'Metodologia',
      watch15: 'Você está em',
      watch16: 'curtiu',
      watch17: 'Avalie',
      watch18: 'Curta',
      watch19: 'Qual o perfil ideal para Network deste conteúdo',
      watch20: 'Olá, estamos buscando perfis que concluíram esta aula, assim que finalizarmos as verificações, te enviamos uma notificação',
      watch21: 'Mulheres',
      watch22: 'Enviar solicitação',
      watch23: 'Sua solicitação para Network está aguardando resposta',
      watch24: 'Estamos buscando o perfil ideal para este Network',
      watch25: 'Central de mensagens',
      watch26: 'Você tem uma solicitação para Network aguardando resposta',
      watch27: 'Você tem um Network ativo para esse conteúdo',
      watch28: 'Criar nota',
      watch29: 'Tempo selecionado',
      watch30: 'Selecione uma etiqueta',
      watch31: 'Título da nota ...',
      watch32: 'Escreva aqui ...',
      watch33: 'Salvar nota',
      watch34: 'Notas',
      watch35: 'Salvar',
      watch36: 'Selecione uma nota',
      watch37: 'Preencha o campo de comentário',
      watch38: 'Comentários',
      watch39: 'Mais recentes',
      watch40: 'Mais antigas',
      watch41: 'Escreva um comentário',
      watch42: 'Ver mais',
      watch43: 'comentários',
      watch44: 'comentário',
      watch45: '',
      watch46: '',
      watch47: '',
      watch48: '',
      watch49: '',
      watch50: ''

    },
    video_header_title: {
      title1: 'Maternidade e Carreira',
      title2: 'Disciplina Positiva',
      title3: 'NetWorking',
      title4: 'Desenvolvimento de Equipes',
      title5: 'Comunicação Estratégica'
    },
    video_job_title: {
      title1: 'Co-Fundadora Todas Group',
      title2: 'Fund. B2Mamy',
      title3: 'Neurocientista'
    },

    video_title: {
      title01: 'Joana - Aula 01',
      title02: 'O que é essencial para o desenvolvimento de equipes, com a CFO da Gerando Falcões, Julia Machado',
      title03: '“Consciência, preparação e conversas difíceis. Como lidar com os desafios da liderança de equipes?”, pela consultora, mentora e palestrante Marisa Salgado',
      title04: 'Autoconhecimento e autoconfiança para desenvolver times - Atendendo a si mesma antes de atender ao outro, pela consultora, mentora e palestrante Marisa Salgado',
      title05: 'Exercício de Comunicação Assertiva, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title06: 'O papel da neurociência na comunicação, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title07: 'Exercício de Disciplina com a coach de carreira, Lívia Kawano',
      title08: 'Disciplina positiva e progresso consistente ao longo do tempo, com Manoela Mitchell, CEO e Co-fundadora da Pipo Saúde',
      title09: 'A importância da constância para construção de uma vida com mais disciplina, com Andrezza Zago, diretora de marketing da BMW Group Financial Services',
      title10: 'Disciplina como uma forma de construir amor próprio, com Andrezza Zago, diretora de marketing da BMW Group Financial Services',
      title11: 'Exercício de Disciplina Positiva',
      title12: 'O poder da disciplina como um meio para resolver problemas de forma mais leve, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title13: 'De mãe para filha e de mãe para mãe com as mentoras do Todas',
      title14: 'A importância da rede de apoio, com Marcela Rezende, Vice-Presidente de Marketing da MadeiraMadeira',
      title15: 'Maternidade e empatia, com Marcela Rezende, Vice-Presidente de Marketing da MadeiraMadeira',
      title16: 'Ser uma mãe e profissional feliz, com Luciana Viegas, idealizadora do Movimento Vidas Negras Com Deficiência Importam',
      title17: 'Maternidade e transformação de vida, com Luciana Viegas, idealizadora do Movimento Vidas Negras Com Deficiência Importam',
      title18: 'A potência de ser mãe, com Dhafyni Mendes, Co-fundadora da Todas Group',
      title19: 'Resolutividade dentro da maternidade, com Dhafyni Mendes, Co-fundadora da Todas Group',
      title20: 'Maternidades plurais, com Dani Junco, CEO e Fundadora da B2Mamy',
      /// new
      title21: 'Como o nosso cérebro lida com as mudanças ao nosso redor?',
      title22: 'Adaptabilidade cognitiva e emocional com Kim Farrell - Diretora Geral e Marketing TikTok América Latina.',
      title23: '10 dicas práticas para Adaptabilidade com Kim Farrell - Diretora Geral e Marketing TikTok América Latina.',
      title24: 'Dicas Todas de Adaptabilidade',
      title25: 'A importância da Adaptabilidade atualmente, com Lívia Kawano - Especialista em Desenvolvimento de Carreiras',
      title26: 'Se adaptando a novos ambientes, com Camila Velzi - Head of E-commerce D2C Mondelez Internacional',
      title27: 'Descobrindo novos caminhos e possibilidades com Dilma Campos - CEO e fundadora da Outra Praia (Live Marketing)',
      title28: 'Novos caminhos com Rachel Muller - Diretora Executiva de Negócios da Nestlé.',
      title29: 'Introdução',
      title30: 'Autoconfiança - Serotonina e Validação - Aula 1',
      title31: 'Autoconfiança - Propósito e Potência - Aula 2',
      title32: 'Camila Farani - 5 pontos essenciais',
      title33: 'Autoconhecimento - Aula 1',
      title34: 'Autoconhecimento - Aula 2',
      title35: 'Inteligências Múltiplas - Aula 3',
      title36: 'Autoimagem - Aula 4',
      title37: 'Autocrítica - Aula 5',
      title38: 'Autoreconhecimento - Aula 6',
      title39: 'Como nos posicionar assertivamente para atingir os nossos objetivos?',
      title40: 'Desenvolvendo um ciclo virtuoso para o Posicionamento',
      title41: 'Posicionamento na prática com Gabriela Prioli - Apresentadora CNN',
      title42: 'Autoestima para se posicionar, com Vivi Duarte - D… de conexões para Meta/Facebook na América Latina',
      title43: 'Planejamento para posicionamento: técnica e métodos para montar seu plano de ação!',
      title44: 'Domínio e Preparação!',
      title45: 'Comunicação não-verbal para posicionamento com Luciana Guimarães - Empreendedora Serial Endeavor',
      title46: 'Comunicação verbal para posicionamento com a Professora de oratória Mª Peroni.',
      title47: 'Protagonismo no posicionamento com Denise Paludett…s, especialista em autogerenciamento de carreira!',
      title48: 'Gerenciamento emocional',
      title49: 'Sistema emocional e a Antecipação',
      title50: 'Equilíbrio emocional com a psiquiatra especialista em estilo de vida Drº Ana Paula Carvalho',
      title51: 'O que é e onde está o bem estar?',
      title52: 'Autogestão de fatores internos',
      title53: 'Roda da Vida',
      title54: 'Estado de Flow',
      title55: 'Conclusão',
      title56: 'Aula 01 - Dra Carla Tieppo - Foco e atenção plena',
      title57: 'Aula 02 - Dra Carla Tieppo - Foco e Atenção Plena',
      title58: 'Aula 01 - Foco e Atenção',
      title59: 'Aula 02 - Foco e Atenção',
      title61: 'Aula 01 - Foco e Consciência',
      title62: 'Aula 02 - Gestão de Estresse e Respiração',
      title63: 'Aula 03 - Organização com Atenção Plena',
      title64: 'Aula 04 - Empatia e Comunicação Consciente',
      title65: 'Aula 05 - Vivendo com Atenção Plena',
      title66: 'Meditação 01 - Três respirações',
      title67: 'Meditação 02 - Respiração Consciente',
      title68: 'Meditação 03 - Escaneamento corporal',
      title69: 'Meditação 04 - Gerando conexão com os outros',
      title70: 'Meditação 05 - Gratidão',
      title71: 'Desconstruindo o conceito de Autoralidade com a neurocientista Drª Carla Tieppo',
      title72: 'Dicas de protagonismo com a neurocientista Drª Carla Tieppo',
      title73: 'Buscando referências para a construção da nossa autoralidade com a jornalista Alexandra Loras',
      title74: 'Autoconhecimento para desenvolver Autoralidade e Protagonismo com a jornalista Alexandra Loras',
      title75: 'Como passar pelas adversidades na jornada em busca da autoralidade e protagonismo com a gerente de educação do Grupo Boticário, Liz Camargo',
      title76: 'Teste de Autoralidade',
      title77: 'Entendendo os resultados do Teste de Autoralidade',
      title78: 'Um passo além na Autoralidade com as Dicas do Todas!',
      title79: '3 Dicas inspiradoras com Mentoras Todas! - Ana Laura Magalhães (Forbes under 30), Flávia Bittencourt (CEO Adidas Brasil) e Suzan Rivetti (Chairman Company Group Johnson & Johnson)',
      title80: 'Ana K. Aula 01',
      title81: 'Ana K. Aula 02',
      title82: 'Construindo uma disciplina mais positiva com pequenos atos, com Andrezza Zago, diretora de marketing da BMW Group Financial Services',
      title83: 'A disciplina positiva na prática, com as mentoras do Todas',
      title84: 'Negociando utilizando a emocionalidade, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title85: 'Exercício de Negociação com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title86: 'Vivências em Negociação com a Diretora Comercial da Cielo, Renata Daltro',
      title87: 'Negociar é para Todas! Dicas de Negociação com as Mentoras do Todas',
      title88: '4 exemplos de Negociação com a Diretora Executiva da Goldman Sachs, Carolina Ragazzi',
      title89: 'Gerenciamento das emoções e autoconhecimento para negociar com a Diretora Executiva da Goldman Sachs, Carolina Ragazzi',
      title90: 'Negociação com foco em pessoas, com a Gerente Comercial da Suzano S.A, Deidi Souza',
      title91: 'Bate papo sobre Negociação com a Diretora Global do Google Pay, Paula Paschoal e a Fundadora da G2 Capital, Camila Farani',
      title92: 'Pensamento Crítico à luz da neurociência',
      title93: 'Construindo o Pensamento Crítico para uma transformação social com a psicóloga Mafoane Odara',
      title94: 'Diálogos internos para desenvolvimento do Pensamento Crítico com a psicóloga Mafoane Odara',
      title95: 'Métodos de Pensamento Crítico com Gabriela Costa, Senior Director da AB Inbev, em New York',
      title96: 'A importância de se informar para desenvolver o Pensamento Crítico com Alessandra Blanco, diretora geral do Yahoo Brasil',
      title97: 'Exemplos e métodos para o Pensamento Crítico com Alessandra Blanco, diretora geral do Yahoo Brasil',
      title98: 'Dicas para pensar criticamente com base na autoestima com a atriz e ativista Tathi Piancastelli',
      title99: 'Estudos e práticas sobre Pensamento Crítico',
      title100: 'Sessão de desenvolvimento de Pensamento Crítico com a terapeuta Gabriela Sayago',
      title101: 'Trocas comunicativas com pessoas diversas, com a terapeuta Gabriela Sayago',
      title102: '5 passos para aprimorar seu Pensamento Crítico no ambiente de trabalho com a terapeuta Gabriela Sayago',
      title103: 'Utilizando as percepções alheias ao nosso favor, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title104: '4 pilares para o desenvolvimento da Marca Pessoal, com a Drª Carla Tieppo, faculty da Singularity University Brazil',
      title105: 'Marca Pessoal, um assunto novo que veio pra ficar! Com Susana Arbex, especialista em Personal Branding',
      title106: 'Dicas práticas sobre Marca Pessoal, com a especialista em Personal Branding, Susana Arbex',
      title107: 'A comunicação como aliada da Marca Pessoal, com Susana Arbex, especialista em Personal Branding',
      title108: 'Teste de Autoanálise para aprimorar sua Marca Pessoal',
      title109: 'Construindo sua Marca Pessoal com as Dicas das Mentoras do Todas',
      title110: 'Marca Pessoal e identidade, com a llíder em Diversidade, Equidade e Inclusão, da Mondelēz International Daniela Sagaz',
      title111: 'Marca Pessoal enquanto legado, com a líder em Diversidade, Equidade e Inclusão, da Mondelēz International Daniela Sagaz',
      title112: 'Vivências e experiências para a construção de uma Marca Pessoal, com Tamara Braga, gerente de Saúde Mental, CEO e Fundadora DiverCidade',
      title113: 'Resultado do Teste de Autoanálise para aprimorar sua Marca Pessoal',
      title114: 'Inovar é para Todas?',
      title115: 'Olhando para o futuro - com a especialista em Inovação Disruptiva Monica Magalhaes',
      title116: 'Principais barreiras para Inovação nas empresas',
      title117: 'Encontrando novas soluções e inovações inesperadas. - com a Co-fundadora do Todas Group Dhafyni Mendes',
      title118: 'Inov(Ação) na prática, com a Co-fundadora do Todas Group Dhafyni Mendes',
      title119: 'Construção de novos futuros, com a Head de Future Beverages da Ambev, Nathália Brandão.',
      title120: 'Métodos para um planejamento estratégico em Inovação, com a Head de Future Beverages da Ambev, Nathália Brandão.',
      title121: '5 exercícios de Inovação com a Drª Carla Tieppo, Neurocientista',
      title122: 'Estudos e práticas sobre liderança com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title123: 'Teste de Liderança com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title124: 'Perspectivas sobre liderança com a diretora geral da Dior Brasil, Daniela Ota',
      title125: 'Expandindo nossa visão sobre liderança e a importância da liderança feminina com Thiago Coelho, vice-presidente comercial da Iguatemi S.A.',
      title126: 'Enxergando privilégios e mudando cenários com Thiago Coelho, vice-presidente comercial da Iguatemi S.A.',
      title127: 'Traçando perfis de liderança com a coach de líderes e autora, Caroline Marcon',
      title128: 'Inteligência emocional para liderar com a coach de líderes, Caroline Marcon',
      title129: 'Liderança como ferramenta para impulsionar carreiras, com a presidente do Match Group, Eugênia Del Vigna',
      title130: 'Dicas práticas para exercitar nossa capacidade de liderança com a presidente do Match Group, Eugênia Del Vigna',
      title131: 'Relações sociais e como nosso cérebro nos ajuda a fazer Networking',
      title132: 'Conheça a história da Head de marketing da Adobe América Latina, Núbia Mota!',
      title133: 'Dicas e segredos de Núbia Mota para um bom Networking!',
      title134: 'Joana - Aula 02',
      title135: 'Trabalhando de forma estratégica suas possibilidades - Com Tati Oliva, CEO Cross Networking.',
      title136: 'Métodos para se destacar no Networking com a CEO da Cross Networking - Tati Oliva',
      title137: 'Práticas e estudos sobre o Networking',
      title138: '“Comunicar não é só falar”, com a Líder de Diversidade & Inclusão, Simone Coutinho',
      title139: 'Conectando com o time utilizando uma comunicação assertiva, com a Líder de Diversidade & Inclusão, Simone Coutinho',
      title140: 'Dicas de Comunicação Assertiva com as Mentoras do Todas',
      title141: 'Comunicação assertiva com a ferramenta da Comunicação Não-Violenta, pela Terapeuta Gabriela Sayago',
      title142: 'Teste - Qual é o seu perfil de comunicação?',
      title143: 'A preparação como elemento chave para se comunicar melhor, com a jornalista Mariana Guidolini',
      title144: 'Três dicas práticas para o desenvolvimento de uma boa comunicação e oratória, com a jornalista Mariana Guidolini',
      title145: 'Usando palavras corretas para se comunicar, com com a jornalista Mariana Guidolini',
      title146: 'Construindo autoconfiança para uma comunicação mais assertiva, com jornalista Mariana Guidolini',
      title147: 'Insights sobre a dinâmica do sistema cerebral com a maternidade, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title148: 'Maternidade e as dinâmicas construídas em relação a carreira das mães, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title149: 'Maternidade e ancestralidade, com Gabriela Ferreira, diretora de Diversidade e Inclusão da Serasa Experian',
      title150: 'Desafios da maternidade e carreira, com Gabriela Ferreira, diretora de Diversidade e Inclusão da Serasa Experian',
      title151: 'A importância da escolha das parcerias dentro da maternidade, com Gabriela Ferreira, diretora de Diversidade e Inclusão da Serasa Experian',
      title152: 'Fatos sobre trabalho e maternidade com Dani Junco, CEO e Fundadora da B2mamy',
      title153: 'Super poderes desenvolvidos na maternidade, com Dani Junco, CEO e Fundadora da B2 Mamy',
      title154: 'O que NÃO fazer para o desenvolvimento de equipes, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title155: 'Exercício de fixação para desenvolver equipes, com a neurocientista Drª Carla Tieppo, faculty da Singularity University Brazil',
      title156: '“Como liderar e se conectar com o seu time?”, com a vice-presidente da bolsa do Brasil, chamada  [B]³, Ana Buchaim',
      title157: '“Mulheres e diversidade na liderança para melhores resultados”, com a vice-presidente da bolsa do Brasil, chamada  [B]³, Ana Buchaim.',
      title158: 'Dicas de Desenvolvimento de equipes com as Mentoras Todas!',
      title159: 'Networking estratégico para carreiras femininas, com Adriana Alves, LinkedIn Top Voice e cofundadora da Lupa!',
      title160: 'Muito mais que currículo, use o LinkedIn estrategicamente para alavancar sua carreira, com Adriana Alves!',
      title161: 'Conexões Genuínas: A Chave para o Sucesso, com Marisa Salgado, LinkedIn Top Voice e consultora de RH!'

    },
    video_config: {
      config01: 'Velocidade de reprodução',
      config02: 'Opções',
      config03: 'Normal'
    },

    description_skills: {
      title1: 'Mente',
      title2: 'Modelagem',
      title3: 'Metodologia',
      sub1: 'Entendendo como seu cérebro reage a essa habilidade',
      sub2: 'Aprendendo com a experiência de uma mulher referência nessa habilidade',
      sub3:
        'Passo a passo para desenvolver essa habilidade em você',

      sub4: '<p>A autoconfiança é a base principal para seu crescimento profissional e pessoal, por isso essa é a nossa primeira habilidade para desenvolver de forma prática no Todas! Aprenda através de neurociência, experiência de Camila Farani, a maior investidora do Brasil e uma metodologia inovadora montada por grandes especialistas em desenvolvimento humano, como construir definitivamente uma vida mais autoconfiante! Vamos juntas?</p>',
      sub5: `<p>Durante 5 meses, estudamos 40 mulheres em posições de destaque na América Latina e mapeamos quais habilidades e pontos foram comum a todas para seu sucesso profissional! Um bom posicionamento foi dos fatores mais citados nessa pesquisa, evidenciando o quanto desenvolver essa habilidade é essencial para mulheres que desejam crescer! Aprenda como se posicionar de forma assertiva e estratégica com as técnicas, métodos e exemplos do time Todas e mulheres que são referências em posicionamento passando seus principais aprendizados em insights para você!

Nesse módulo:

Aula 1 e 2 :Neurociência do posicionamento com neurocientista e faculty Singularity: Dra Carla Tieppo

Aula 3: Posicionamento na prática com Gabriela Prioli- apresentadora CNN

Aula 4: Auto estima para posicionamento com Vivi Duarte- Diretora de conexões para Meta/Facebook na América Latina

Aula 5: Planejamento para posicionamento: técnica e métodos para montar seu plano de ação!

Aula 6: Domínio e Preparação: Como executar seu plano de posicionamento!

Aula 7: Comunicação não-verbal para posicionamento com Luciana Guimarães- Empreendedora Serial Endeavor

Aula 8: Comunicação verbal para posicionamento com Professora de oratória Mª Peroni.

Aula 9: Protagonismo no posicionamento com Denise Paludetto- Diretora de RH e mentora de líderes, especialista em autogerenciamento de carreira!

Aproveite seu momento de crescimento!</p>`,
      sub6: `<p>Confira as experiências e conselhos de Nicole Vendramini para a construção de hábitos mais saudáveis pautados em foco e atenção plena.

      Nicole é co-fundadora e co-CEO da plataforma de saúde e bem-estar Holistix.

      Após muitos anos atuando como especialista em marketing dentro de grandes marcas da indústria, Nicole resolveu mergulhar em estudos de saúde holística e aplicar seus conhecimentos desenvolvendo os produtos de sua empresa. Agora ela passa todas as suas dicas para você!</strong></p>`,
      sub7: '<p>Existe fórmula certa para a autoralidade? Ser autoral e se colocar como protagonista da nossa própria história, surge também com uma série de desafios. Como lidar com a frustração, projetos que não dão certo e dar passos para trás, podem acontecer ao longo do caminho.</p>'
    },
    summary: {
      title1: `<p>Entenda nessa aula o que produz autoconfian&ccedil;a para seu c&eacute;rebro,&nbsp;e qual forma de pensar e agir potencializa ou diminui sua autoconfian&ccedil;a!</p>\r\n' +
      '\r\n' +
      '<p>Aprenda com a Dra Carla Tieppo, pioneira do estudo do c&eacute;rebro no comportamento humano e refer&ecirc;ncia em neuroci&ecirc;ncia na Am&eacute;rica Latina! Carla faz parte do corpo docente no Brasil da Singularity, o maior centro de inova&ccedil;&atilde;o do mundo, fundada no campo de pesquisa da NASA! Aproveite sua aula!&nbsp;</p>`
    },
    assessment: {
      title1: 'Pesquisa de Impacto',
      title2: 'Etapa essencial para que você possa identificar de forma clara seus pontos fortes e descobrir oportunidades de melhoria,',
      title3: 'levará apenas 3 minutos do seu tempo.',
      title4: 'Vamos lá',
      title5: 'Tempo estimado 3 minutos',
      title6: 'AVISO: Reposta as questões abaixo. Essa é uma pesquisa totalmente anônima.',
      title7: 'Analise as frases a seguir e selecione de acordo com os tipos de escala:',
      title8: '(1) Raramente, (2) Às vezes, (3) Neutro, (4) Com frequência, (5) Frequentemente.',
      title9: 'Você não selecionou todas as perguntas',
      title10: 'Respostas salvas com sucesso ',
      title11: 'Descoberta de Potências',
      title12: 'Este processo oferece a oportunidade única para explorar suas habilidades e talentos específicos, o que resultará na personalização do seu perfil com metas e objetivos,',
      title13: ' levará apenas 5 minutos do seu tempo.',
      title14: 'Tempo estimado 5 minutos',
      title15: 'O quanto você se identifica com essa frase ?',
      title16: 'Resultado de Perfil',
      title17: 'Ao reconhecer nossas habilidades e talentos, podemos direcionar esforços para áreas onde somos melhores, enquanto as oportunidades de melhoria nos impulsionam a crescer e aprimorar habilidades técnicas.',
      title18: 'Por favor, responda à pesquisa de impacto e potência para visualizar seu resultado.',
      title19: 'confira seu Resultado',
      title20: 'HABILIDADES POTENCIALIZADORES',
      title21: 'ANALISE DAS HABILIDADES',
      title22: 'Começar',
      assessmentButton: 'Finalizar'

    },
    assessment_question: {
      info1: 'Raramente',
      info2: 'Frequentemente',
      info3: 'Finalizar',
      info4: 'Às vezes',
      info5: 'Neutro',
      info6: 'Com frequência',
      title1: 'Eu me sinto confiante/capaz de assumir novos desafios.',
      title2: 'Eu conheço meus pontos fortes.',
      title3: 'Eu consigo expor minhas ideias com clareza.',
      title4: 'Eu consigo equilibrar minha vida pessoal e profissional.',
      title5: 'Eu tenho certeza que posso chegar mais longe.',
      title6: 'Eu percebo que as pessoas ao meu redor notam mudanças positivas na minha postura e atitudes.',
      title7: 'Eu acredito que já inspirei outras mulheres a crescerem também.',
      title8: 'Eu percebo que a empresa onde trabalho é um bom lugar para mulheres crescerem.',
      title9: 'Eu vejo que na minha empresa mulheres têm oportunidades iguais às dos homens.',
      title10: 'Meu gestor direto apoia meu crescimento.',
      title11: 'A liderança sênior da empresa apoia o crescimento de mulheres.',
      title12: 'Eu sinto um senso de comunidade entre as mulheres na empresa onde trabalho.',
      title13: 'Eu aceito correr riscos calculados para novos projetos.',
      title14: 'Eu aceito os imprevistos e os converto em oportunidades.',
      title15: 'Eu acredito que chegarei onde quero, independente dos nãos que receber no caminho.',
      title16: 'Eu conheço meus gatilhos emocionais e pondero minhas próprias reações.',
      title17: 'Eu consigo enxergar problemas complexos e traduzi-los de forma simples.',
      title18: 'Eu consigo estabelecer relacionamentos duradouros e mutuamente vantajosos.',
      title19: 'Eu consigo influenciar nas decisões ao meu redor.',
      title20: 'Eu consigo me comprometer do início ao fim nas atividades que proponho.',
      title21: 'Eu crio conexão e confiança de forma rápida e assertiva.',
      title22: 'Eu tenho uma visão clara e impulsiono o time a realizá-la com sucesso.',
      title23: 'Eu entendo a necessidade individual de cada um do time e consigo apoiar.',
      title24: 'Eu entendo o valor de ter constância nas ações.',
      title25: 'Eu estou aberta a avaliar que existem pontos de vista diferentes para o mesmo desafio.',
      title26: 'Eu estou aberta a ceder e tenho claro minha margem de flexibilidade.',
      title27: 'Eu me interesso genuinamente no ponto de vista do outro.',
      title28: 'Eu me sinto capaz de tomar decisões individualmente e baseadas na minha própria análise.',
      title29: 'Eu me sinto confortável em colocar minha opinião com clareza e firmeza, independentemente da opinião de outros.',
      title30: 'Eu reconheço meus erros e consigo mudar a postura em relação a eles.',
      title31: 'Eu reconheço meus limites e sei quando pedir ajuda.',
      title32: 'Eu reconheço o valor dos meus talentos para atingir resultados profissionais.',
      title33: 'Eu reconheço que existem várias soluções para o mesmo problema.',
      title34: 'Eu respeito meu próprio estilo para estabelecer um modelo de trabalho.',
      title35: 'Eu sei balancear as demandas urgentes e as importantes.',
      title36: 'Eu sei dar o primeiro passo para me conectar com outras pessoas para trocas profissionais.',
      title37: 'Eu sei escolher o momento oportuno para me posicionar ou deixar passar.',
      title38: 'Eu sei minhas fortalezas e consigo utilizá-las a meu favor.',
      title39: 'Eu sigo com a melhor ideia mesmo que não seja minha.',
      title40: 'Eu sou capaz de trazer soluções novas para um problema já discutido.',
      title41: 'Eu tenho a consciência de que tudo em mim comunica e consigo ajustar de acordo com a necessidade.',
      title42: 'Eu tenho facilidade de aprender novas habilidades e colocá-las em prática.',
      title43: 'Eu tenho leitura completa da situação e influencio positivamente o ambiente e resultados.',
      title44: 'Eu tenho orgulho da minha trajetória.',
      title45: 'Eu tenho uma convicção inabalável que vou conquistar o que desejo',
      title46: 'Eu consigo criar narrativas envolventes que conectam minha mensagem aos objetivos estratégicos.',
      title47: 'Eu adapto a comunicação e seu conteúdo de acordo com o público-alvo com eficiência.',
      title48: 'Eu sou capaz de persuadir e influenciar outros por meio da minha comunicação.'

    },
    assessment_description: {
      title1: 'Você é ótima em networking! Sua habilidade social é incrível, assim como sua capacidade de ler as pessoas e ajudá-las em seus objetivos quando necessário, e da mesma forma construir relações que também te dão ferramentas para encontrar ajuda quando você precisa em seus desafios. Você tem a capacidade de conectar pessoas, criando pontes e soluções. Essa é uma habilidade muito potencializadora em crescimento profissional..Parabéns! Sabemos que em nossa vida tão agitada em qua mulheres têm tantas demandas, a manutenção do networking é um grande desafio. Já que você tem facilidade em abrir conexões, não se esqueça de manter a chama deles acesa. Organize sua agenda para ter um espaço específico para continuidade de interação com seus contatos, desde um calendário de aniversários ou datas importantes, até mensagens para saber como estão as coisas, isso potencializará ainda mais sua agenda de networking e a força de suas relações. No nosso módulo de networking na Todas, a aula de Núbia Motta, expert em relações entre empresas na América Latina dá várias dicas de como cada vez mais aumentar seu networking de forma genuína e estratégica. Aliás, networking entre mulheres é uma das nossas missões aqui na Todas, então conte com a gente para criar grandes e potentes conexões para você também.. Todas crescendo juntas! ',
      title2: 'Inovação é sua fortaleza! Você tem a capacidade de enxergar movimentos que a maioria das pessoas ainda nem percebeu, transformando sua visão de negócios em poderosa, adicionando perspectivas muito interessantes ao cenário atual e gerando transformações. Isso é muito potente! Pessoas inovadoras são agentes das mudanças que o mundo precisa, então saiba reconhecer seu valor em ser essa mulher inovadora que você é! Pessoas inovadoras, pensam de maneira diferente, antecipando tendências e conectando pontos que ainda parecem desconectados para os demais, então uma ótima dica para potencializar ainda mais seu poder de inovação é investir em aprimorar sua comunicação para que você tenha ferramentas para explicar com clareza o que já parece óbvio para você mas as outras pessoas ainda não conseguem enxergar, dessa forma além de cada vez mais se posicionar como uma profissional visionária, você também une aliados para gerar mudanças inovadoras com você! Um ponto de atenção para pessoas inovadoras é que por ter muitas boas ideias, o foco de execução muitas vezes pode ficar confuso, saiba priorizar e avaliar qual ideia merece ser colocada em prática no momento e quais ficarão para um momento próximo. No nosso app tem uma aula de inovação com nossa cofundadora Dhafyni Mendes, que ela conta um método que aprendeu com Walt Disney sobre como escolher a melhor ideia para melhor execução atual, você vai curtir! Outra dica incrível para você, é assistir a inspiradora futurista Mônica Magalhães falando sobre os elementos de inovação que mais geram valor em empresas no mundo.. com certeza essa aula vai impulsionar ainda mais seu talento inovador para te levar cada vez mais alto! ',
      title3: 'Autoralidade e protagonismo são lugares de força na sua vida! Você consegue usar suas características pessoais como diferencial para se destacar, tornando-se única no ambiente que se encontra. Essa é uma característica de profissionais brilhantes e ficamos aqui TODAS muito felizes quando encontramos mulheres que exercem essa fortaleza! Em um mundo com tantos vieses para que mulheres se "encaixem" no mercado de trabalho, profissionais que têm orgulho de suas diferenças e sabem usá-las com protagonismo de maneira positiva no mercado são agentes de grandes mudanças em todo o ecossistema profissional. Acredite, você inspira muito mais do que pode imaginar!  Pessoas ao seu redor que você nem imagina, encontram na sua coragem de ser quem você é, um caminho para serem elas mesmas também, e isso é transformacional! Porém, o outro lado da moeda de quando você assume com orgulho sua trajetória e não aceita se apagar para se igualar ou encaixar, é que nem todo mundo vai entender sua fortaleza autoral e protagonista, então certifique se de estar entre pessoas que valorizam seu diferencial, busque aliados internos no seu trabalho que enxerguem seu brilhantismo e encontre espaços que seu talento possa ser propagado. Para te motivar, assista em nosso app a aula de Autoralidade e Protagonismo com Alexandra Loras, onde ela conta em sua inspiradora trajetória como ela poderia ter se deixado limitar por eventos em sua vida que usou para potencializar ainda mais seu sucesso, se posicionando em lugares em que poderia exercer todo seu diferencial. O desejo de Todas nós, é que você brilhe cada vez mais! ',
      title4: 'Posicionamento é um ponto forte seu! Você tem a incrível capacidade de captar informações do ambiente, encontrar soluções e apresentá- las de forma estratégica. Saiba que posicionamento é uma das principais dificuldades no crescimento de carreiras femininas, e se você já domina essa habilidade, sua vantagem competitiva de mercado é imensa. A boa notícia é que posicionamento é como um músculo que quanto mais exercitado mais fortalecido fica e esse seu ponto forte ficará mais extraordinário quando mais você usá-lo! Não perca oportunidades de expor seu ponto de vista em reuniões estratégicas e apresentar seus resultados em contextos relevantes, e o ponto de atenção sobre sua facilidade de posicionamento é saber escolher no que você realmente precisa de posicionar. Não gaste sua energia se posicionando sobre tudo, uma das maiores inteligências do posicionamento é ser estratégica sobre o melhor momento e maneira de fazê-lo, no nosso app a aula de Gabriela Prioli sobre posicionamento estratégico aborda exatamente isso, dica boa para você!     Abuse do seu poder de posicionamento e conquiste o mundo!',
      title5: 'Autoconfiança é uma fortaleza sua! Você tem conhecimento sobre seus pontos fortes e sabe como usá-los, trazendo os aprendizados que viveu ao longo de sua jornada como ferramentas internas para agir em desafios externos. Essa é uma habilidade frequente nas mulheres em topo de liderança, sinal de que você está no caminho certo para alcançar seus objetivos e inspirar mais mulheres a realizarem os seus sonhos também... Parabéns! \n' +
        'Por outro lado, um ponto de atenção no excesso de autoconfiança é o autocentramento, quando você fica tão focada em sua visão, que passa a enxergar menos outros pontos de vista ao seu redor que podem ser partes também importantes na resolução de um problema. Exercite sua escuta ativa também para que sua autoconfiança seja cada vez mais potencializada de forma positiva e amplie cada vez mais seu repertório de lições e conquistas. Na aula de autoconfiança de Camila Farani no nosso app, ela que foi considerada das 500 mulheres mais influentes da América Latina dá ótimas dicas sobre gerenciamento de autoconfiança para você voar cada vez mais alto!  Estamos TODAS torcendo por você e ansiosas para aplaudir suas próximas realizações, pois temos certeza que você alcançará muitas! ',
      title6: 'Adaptabilidade é um ponto forte seu! Em um mundo que muda tanto e tão rápido, essa sua capacidade de conseguir perceber as transformações e rapidamente fazer mudanças para potenciar resultados em novos cenários, é muito valiosa! Não por acaso, após a pandemia, a adaptabilidade se tornou a habilidade mais buscada no ambiente profissional, então usufrua ao máximo desse momento para destacar seu talento. Você é sensível para captar recentes informações, sagaz para pensar inovadoras possíveis soluções e ágil para executar novas tentativas.. e isso é incrível! Valorize muito essa sua fortaleza! Um ponto de cuidado para mulheres super adaptáveis é não se sobreadaptar! Não passar por cima do que você acredita se adaptando com algo que não concorda! Em sua aula de adaptabilidade na Todas, Rachel Muller, inspiradora executiva da Nestlé aborda exatamente esse tema entre o limite saudável para adaptabilidade, com certeza você vai gostar.. ótima dica para você impulsionar ainda mais essa sua competência tão poderosa que é a adaptabilidade! Não deixe de assistir também, Kim Farrel, Diretora Geral de Operações de Marketing do TikTok na América Latina que aborda seus 10 passos para potencializar adaptabilidade, e voe cada vez mais alto! ',
      title7: 'Negociação é sua fortaleza! Você sabe como ler cenários e encontrar os melhores argumentos para resolver conflitos e criar boas oportunidades. Você também tem excelente poder de convencimento e interação, sabendo como mostrar o melhor lado da situação, criando confiança e conexão com seu posicionamento. Seu poder de escuta, comunicação e saber quando necessita flexibilizar, também te fazem ser destaque nas suas negociações.. Parabéns! Essa é uma habilidade poderosa em crescimento profissional feminino! Um ponto de atenção em pessoas que são ótimas em negociações, é também ficar atenta aos entregáveis que você negociou, para que sua próxima negociação com essa pessoa seja tão eficiente como a primeira. Em sua aula de Negociação na Todas, Renata Daltro, VP na Cielo, que é reconhecida pelo seu poder de negociação conta sua trajetória para se tornar uma referência em um ambiente ainda predominantemente masculino. Com certeza irá te inspirar também a usar seu talento em negociação para alcançar todos os ambientes que você sonha! ',
      title8: 'Disciplina é seu ponto forte! Você tem a capacidade de enxergar com clareza quais são os pontos necessários para te levar aos objetivos que deseja, construindo metas estruturadas para execução desse cenário, atribuindo responsabilidade e constância nesse processo. Parabéns! As pessoas mais bem sucedidas do mundo, têm disciplina como ponto essencial do seu extraordinário resultado, sinal que você já está na trilha correta para seu crescimento. Você também tem uma super positiva habilidade de organização e priorização de tarefas, valorize isso e aplique cada vez mais nos processos de seu trabalho e times, isso trará cada vez mais eficiência para as operações em que você está inserida. Um ponto de atenção para pessoas altamente determinadas é o excesso de cobrança, não esqueça de ser também mais gentil com você no processo de alcançar seus objetivos! Seja legal com você! No nosso app temos um módulo inteiro sobre disciplina positiva com grandes líderes da América Latina para potencializar cada vez mais essa sua habilidade, e uma ótica dica para você é também assistir a aula de inteligência emocional com a Dra Ana Paula Carvalho, psiquiatra Harvard Medical School, que aborda como sermos mais gentis com nós mesmas de forma prática do meio da nossa jornada de crescimento. Todas crescendo juntas!',
      title9: 'Inteligência Emocional é uma potência sua! Você tem a capacidade de analisar os fatos e escolher a melhor forma de reagir, diminuindo os impactos negativos para sua vida e das pessoas que a cercam, e essa é uma das habilidades mais valiosas na nossa sociedade atual! Muitas vezes nossas potências são tão naturais para a gente que não conseguimos mensurar o valor delas, você possivelmente tem essa sensatez natural e nem consegue imaginar o resultado tão positivo que sua presença gera no seu ambiente profissional e pessoal. Mas já que a consciência é o primeiro passo para o crescimento, agora que você já sabe que inteligência emocional é sua fortaleza, use muito isso ao seu favor e valorize essa sua força. Não perca oportunidades de expor seu ponto de vista, já que você tem ótima visão e entendimento de reações, efeitos e consequências, use sua sabedoria para se destacar. Ponto de atenção, como você tem grande capacidade de entendimento emocional do ambiente, cuide para que pessoas ao seu redor não abusem da sua compreensão sendo desrespeitosas com você e esperando sua forma tranquila de reação, te sobrecarregando de tarefas ou não reconhecendo de forma adequada suas entregas e valor. Lembre-se do quão valiosa você é, e use todo seu bom discernimento emocional para encontrar a melhor maneira para que seja valorizada como merece. Com certeza essa sua habilidade te levará muito longe!! ',
      title10: 'Uau, sua fortaleza é pensamento crítico! Você tem a incrível capacidade de reunir diferentes informações, analisar opostos pontos de vista com sensatez, e encontrar elementos em diferentes cenários para construir suas opiniões, é isso é muito valioso! Em um mundo tão polarizado como vivemos em que a maioria das pessoas só quer convencer o outro sobre suas convicções, uma pessoa como você que sabe ouvir e dialogar com diferentes posições e está disposta a mudar suas opiniões sobre um tema, pesando os fatos...é além de raro, muito necessário para o crescimento tanto de corporações como a evolução da sociedade. Você é agente das mudanças que nosso mundo precisa! Seu poder de pensamento crítico te dá uma imensa vantagem competitiva para sugerir soluções, confie nessa sua habilidade e verbalize mais suas opiniões em reuniões de trabalhos, com sua liderança ou pares. Outro ponto positivo na sua fortaleza em pensamento crítico, é contribuir para o clima positivo organizacional já que você tem o poder de leitura de diferentes cenários, podendo diminuir conflitos e impactos em pessoas que pensam de forma completamente oposta no time. Mafoane Odara, renomada líder na América Latina, que é conhecida como construtora de pontes em ambientes de conflitos, aborda em sua aula de pensamento crítico na Todas, como equilibrar os lados positivos e negativos de ser uma mulher com pensamento crítico e potencializar ao máximo essa habilidade magnífica que você já tem! Estamos por aqui TODAS torcendo por você e te apoiando, pois  temos convicção que mais pessoas com alto pensamento crítico como você, nos altos postos de liderança, é o caminho para organizações melhores para TODAS as pessoas! ',
      title11: 'Liderança é uma fortaleza sua! Você tem uma forte visão de negócios, obstinação para construir uma rota de objetivo e capacidade de criar influência e confiança para motivar aliados a caminharem com você nessa direção! Você sabe como inspirar, desenvolver e gerenciar conflitos em times, encontrando soluções e trazendo crescimento. Acreditamos muito que mais mulheres em posições de liderança, melhoram o ambiente de trabalho para TODAS as pessoas, então contamos com você para essa missão.. obrigada por ser a mulher que você é, sabemos que não foi fácil chegar até aqui onde você está hoje e você tem uma lista imensa de grandes desafios que enfrentou e ainda enfrenta, então agora queremos te pedir para tirar 30 segundos para respirar valorizando muito a liderança que você construiu dentro de você! Que agora você sinta orgulho da sua trajetória e força! Que nessa respiração você renove energias para chegar ainda mais alto alcançando todos os sonhos que você ambiciona! Para te inspirar nessa jornada, no nosso app temos grandes líderes da América Latina dividindo com sinceridade desafios que enfrentaram em suas lideranças e como ultrapassaram esses obstáculos, não deixe de assistir Flávia Bittencourt: CEO Adidas América Latina, Fiama Zariffe: CEO Airbnb, Daniela Ota: CEO Dior e Beatriz Bottesi: CMO Meta América Latina, que entre muitas outras mulheres inspiradoras no nosso app, falam com abertamente sobre temas que com certeza você irá se identificar. Estamos aqui TODAS torcendo pelo seu crescimento e sucesso!',
      title12: 'Você tem uma ótima comunicação estratégica! Sua habilidade de transmitir ideias de forma clara, assertiva e empática permite que você construa conexões significativas e alcance seus objetivos com mais facilidade. Você sabe como adaptar sua mensagem ao contexto e ao público, garantindo que sua comunicação seja sempre eficaz e impactante. Essa é uma competência essencial para o crescimento profissional. Parabéns! Sabemos que, com a rotina intensa e as múltiplas demandas que as mulheres enfrentam, manter uma comunicação alinhada e estratégica pode ser desafiador. Por isso, lembre-se de praticar a escuta ativa, estruturar bem suas mensagens e sempre considerar o impacto das suas palavras. Pequenos ajustes no dia a dia podem tornar sua comunicação ainda mais poderosa. Por outro lado, um ponto de atenção na comunicação estratégica é garantir que sua mensagem seja sempre clara e empática. Em alguns momentos, o excesso de planejamento pode tornar a comunicação mais técnica e menos humanizada, dificultando conexões genuínas. Exercitar a escuta ativa e a adaptação da linguagem ao contexto pode potencializar ainda mais sua influência e garantir que sua comunicação seja sempre assertiva e inspiradora. Em nosso módulo de Comunicação Estratégica na Todas, especialistas como Dra. Carla Tieppo e Simone Coutinho trazem insights valiosos para aprimorar sua comunicação, desde técnicas de neurociência até a comunicação não-violenta. Aqui, acreditamos que comunicar bem é um dos pilares do sucesso, e estamos juntas para fortalecer essa habilidade. Vamos potencializar sua comunicação?'
    },
    assessment_opt_in: {
      title: 'Passo',
      title1: 'voltar',
      title2: 'Cadastro - Plano Corporativo',
      title3: 'Inscreva-se agora para acessar a plataforma e faça parte desse movimento.',
      title4: 'Nome',
      title5: 'Email',
      title6: 'Recebido por e-mail',
      title7: 'Codigo de Ativação',
      title8: 'Recebido por e-mail',
      title9: 'Número de Celular',
      title10: 'Crie uma Senha',
      title11: 'Confirmar Senha',
      title12: 'Me reconheço como mulher*',
      title13: 'Aceito os Termos de uso e Politica de Privacidade*',
      title14: 'Aceito os Termos de uso e Politica de Privacidade da Todas Group e JNJ*',
      title15: 'Aceito os Termos de uso e Politica de Privacidade da Todas Group e NTT*',
      title16: ' Ao se inscrever, você concorda com nossos',
      title17: 'Termos de Uso',
      title18: '. Além disso,',
      title19: ' você aceita o Compartilhamento de Dados para fins de mapeamento',
      title20: ' de dados demográficos e concorda em receber novidades e promoções.',
      title21: 'Política de Privacidade',
      title22: 'Inscreva-se',
      title23: 'O campo obrigatorio',
      title24: 'O codigo de ativação deve ter no minimo 6 digitos',
      title25: 'Complete seu Perfil!',
      title26: 'Preencha algumas informações sobre sua empresa e a sua posição',
      title27: 'Empresa',
      title28: 'Vice-presidência',
      title29: 'Selecione a sua unidade de negócios',
      title30: 'Cargo Atual',
      title31: 'Insira o link do seu perfil',
      title32: 'Finalizar',
      title33: 'Número de CPF',
      title34: 'Inicie sua jornada na Todas!',
      title35: 'Selecione uma opção para ingressar na plataforma',
      title36: 'Plano Corporativo',
      title37: 'Para utilizar o beneficio oferecido pela sua empresa',
      title38: 'Plano Individual',
      title39: 'Junte-se à nossa comunidade como pessoa física',
      title40: 'Já tem uma conta?',
      title41: 'Bem-vinda ao futura da liderança feminina! ',
      title42: ' Preparada para impulsionar sua carreira? Para isso, precisamos de algumas informações suas. Não se preocupe,',
      title43: 'levará apenas 10 minutos do seu tempo.',
      title44: 'Vamos lá',
      title45: '',
      title46: '',
      title47: '',
      title48: '',
      title49: '',
      title50: ''
    },

    networks: {
      title1: 'Nenhum network',
      title2: 'Nenhum chat selecionado',
      title3: 'Você ja assistiu',
      title4: 'Assistir',
      title5: 'Deseja ir para o conteúdo completo da solicitação?',
      title6: ' Ir para o conteúdo completo',
      title7: 'Cancelar',
      title8: 'Sua solicitação para Network está aguardando resposta',
      title9: 'está pedindo Network para este conteúdo',
      title10: 'Estamos buscando o perfil ideal para este Network',
      title11: 'Aceitar',
      title12: 'aceitou sua solicitação de Network para o conteúdo',
      title13: 'Você aceitou a solicitação de Network de',
      title14: 'para este conteúdo',
      title15: 'Começar Network',
      title16: 'Excluir Network',
      title17: 'Deseja excluir este pedido de Network?',
      title18: 'Excluir',
      title19: 'Cancelar',
      title20: 'Sua solicitação foi aceita',
      title21: 'Aguardando resposta',
      title22: 'Pedido de network aceito',
      title23: 'Pedido de network',
      title24: 'Detalhes',
      title25: 'Conteúdo',
      title26: 'Começar',
      title27: 'Aceitar',
      title28: '',
      title29: '',
      title30: ''
    },
    todas: {
      title1: 'Criar publicação ',
      title2: 'Usuária anônima',
      title3: 'respostas',
      title4: 'Trabalho',
      title5: 'Familia',
      title6: 'Saúde',
      title7: 'Lazer',
      title8: 'Assédio',
      title9: 'Título',
      title10: 'Descrição',
      title11: 'Enviar',
      title12: 'Excluir',
      title13: 'Enviar como usuária anônima?',
      title14: 'Sim',
      title15: 'Não',
      title16: 'Selecione o tema',
      title17: '',
      title18: '',
      title19: '',
      title20: ''
    },
    home: {
      message: 'Olá Mundo!'
    },
    faq: {
      whatsSpan1: 'Suporte por Whatsapp',
      whatsSpan2: 'Iniciar conversa por WhatsApp',
      whatsSpan3: 'Perguntas frequentes',
      whatsTxT: 'Tentou tirar sua dúvida no “Perguntas frequentes” e não achou o que buscava? Entre em contato com nosso suporte:',
      message: 'Nenhuma pergunta cadastrada',
      question_1: 'O que é o Todas Group?',
      question_2: 'Como faço para baixar o aplicativo do Todas?',
      question_3: 'Quais conteúdos eu vou encontrar na plataforma?',
      question_4: 'Quanto custa o Todas Group ?',
      question_5: 'Os cursos tem certificado?',
      question_6: 'Como funciona a comunidade de mulheres?',
      question_7: 'Como faço para cancelar minha assinatura?',
      answer_1: '<p>Somos uma plataforma digital que democratiza o acesso a l&iacute;deres altamente inspiradoras para impulsionar jornadas profissionais femininas.</p>\r\n\r\n<p>O aplicativo Todas foi desenvolvido para disponibilizar uma trilha de conhecimento in&eacute;dita com base nas habilidades das mulheres que mais crescem no Brasil e no mundo.</p>\r\n\r\n<p>As aulas e exerc&iacute;cios s&atilde;o entregues atrav&eacute;s de uma metodologia inovadora, capaz de te ajudar a desenvolver um modelo mental de sucesso em poucas semanas.</p>\r\n\r\n<p>Tamb&eacute;m contamos com uma comunidade repleta de mulheres para trocar experi&ecirc;ncias, al&eacute;m de gamifica&ccedil;&atilde;o para facilitar o aprendizado e recompensas pelo seu desempenho.</p>',
      answer_2: '<p><a href="https://apps.apple.com/br/app/todas-group/id1564341168" rel="noopener" target="_blank">IOS</a></p>\r\n\r\n<p><a href="https://play.google.com/store/apps/details?id=com.todasgroup&amp;hl=pt" rel="noopener" target="_blank">GooglePlay</a></p>',
      answer_3: '<p>Voc&ecirc; ter&aacute; acesso a nossa trilha de conhecimento com as habilidades essenciais para o seu desenvolvimento profissional, cada habilidade &eacute; composta por aulas em v&iacute;deo e exerc&iacute;cios pr&aacute;ticos.</p>\r\n\r\n<p>N&atilde;o se preocupe, tudo foi pensado levando em considera&ccedil;&atilde;o a rotina da mulher moderna, as aulas s&atilde;o r&aacute;pidas e din&acirc;micas!</p>\r\n\r\n<p>Al&eacute;m disso, voc&ecirc; ter&aacute; acesso ao Todas Livros com resumos em &aacute;udio e talks com lideran&ccedil;as femininas reconhecidas internacionalmente.</p>',
      answer_4: '<p>Anual &agrave; vista &ndash; R$2.600,00<br />\r\nAnual parcelado &ndash; 12x de R$260,00</p>',
      answer_5: '<p>Ao completar cada habilidade voc&ecirc; ganha um certificado relacionado a ela, e ainda pode compartilh&aacute;-lo no seu Linkedin!</p>',
      answer_6: '<p>Nossa comunidade &eacute; feita de mulheres diversas, com os mais variados desafios e experi&ecirc;ncias profissionais.</p>\r\n\r\n<p>Atrav&eacute;s do #TodasJuntas, voc&ecirc; ter&aacute; a oportunidade de compartilhar d&uacute;vidas e desafios, dentro de 48 horas voc&ecirc; receber&aacute; uma resposta.</p>',
      answer_7: '<p><strong>iOS:</strong>&nbsp;Para cancelar sua assinatura via App Store, siga os passos abaixo:</p>\r\n\r\n<p>1- Acesse &ldquo;Configura&ccedil;&otilde;es&rdquo;&nbsp;</p>\r\n\r\n<p>2- Clique no seu nome</p>\r\n\r\n<p>3- Clique em &ldquo;Assinaturas&rdquo;</p>\r\n\r\n<p>4- Clique na sua assinatura Todas</p>\r\n\r\n<p>5- Clique em &ldquo;Cancelar Assinatura&rdquo;</p>\r\n\r\n<p><strong>Android:</strong>&nbsp;Para cancelar sua assinatura via Play Store, siga os passos abaixo:</p>\r\n\r\n<p>1- Acesse a Play Store</p>\r\n\r\n<p>2- No canto superior, clique na sua foto de perfil</p>\r\n\r\n<p>3- Clique em &ldquo;Pagamentos e Assinaturas&rdquo;</p>\r\n\r\n<p>4- Clique em &ldquo;Assinaturas&rdquo;</p>\r\n\r\n<p>5- Selecione sua assinatura Todas</p>\r\n\r\n<p>6- Clique em &ldquo;Cancelar assinatura&rdquo;</p>'
    },
    PageNotFound: {
      title: 'Página não encontrada',
      p: 'Tente nossos outros conteúdos'
    },
    sign_up: {
      title1: 'Faça seu cadastro',
      title2: 'Nome Completo',
      title3: 'E-mail',
      title4: 'Celular',
      title5: 'Senha',
      title6: 'Confirmar senha',
      title7: 'Me reconheço como mulher',
      title8: 'Cadastrar',
      title9: 'Cadastrando',
      title10: 'Voltar para login',
      title11: 'Os ',
      title12: 'aprendizados e direcionamentos que as grandes líderes do Brasil',
      title13: 'aprenderam em muitos anos, grandes especializações e diversas experiências.',
      title14: 'Agora disponíveis para você!',
      title15: 'Você cresce, Todas crescem!',
      title16: 'Faça Parte!',
      title17: 'CPF',
      title18: '',
      title19: '',
      title20: ''
    },
    recovery: {
      title1: 'Esqueci minha senha',
      title2: 'E-mail',
      title3: 'Enviar código',
      title4: 'Enviando',
      title5: 'Fazer login',
      title6: 'Recuperar senha',
      title7: 'Código',
      title8: 'Senha',
      title9: 'Confirmar senha',
      title10: 'Recuperar',
      title11: 'Recuperando',
      title12: '',
      title13: '',
      title14: '',
      title15: '',
      title16: '',
      title17: '',
      title18: '',
      title19: '',
      title20: ''
    },
    formValidations: {
      ValidateRequiredField: 'Este campo é obrigatório',
      ValidateInvalidEmail: 'E-mail inválido',
      ValidateMinPassword: 'A senha deve ter pelo menos 8 caracteres',
      ValidateMaxPassword: 'A senha deve ter no máximo 24 caracteres',
      ValidatePasswordNotEquals: 'As senhas não coincidem',
      ValidateInvalidCPF: 'CPF inválido',
      ValidateInvalidCardNumber: 'Número de cartão inválido',
      ValidateInvalidDateExp: 'Data de expiração inválida',
      ValidateOnlyNumbers: 'Este campo aceita apenas números',
      ValidateMinName: 'O nome deve ter pelo menos 2 caracteres',
      ValidateMaxToken: 'O token deve ter no máximo 5 caracteres',
      ValidationNotFound: "Validação '{{name}}' não encontrada",
      AlertSendCode: 'Código enviado com sucesso.',
      AlertUserNotVerify: 'Usuário não verificado.',
      AlertSendCodeError: 'Erro ao enviar o código. Tente novamente.'
    }
  }
}
